import React, { useState } from 'react'
import { List, ListItemButton, ListItemText, Collapse, IconButton } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const Steps = ({ steps, handleSeek }) => {
  const [open, setOpen] = useState({})
  const [activeStep, setActiveStep] = useState(steps[0].id)

  const handleToggle = (item) => {
    setOpen((prevState) => ({
      ...prevState,
      [item.id]: !prevState[item.id],
    }))
  }

  const handleItemClick = (item) => {
    if (item.breakpoint !== undefined) {
      handleSeek(item.breakpoint)
    }
    setActiveStep(item.id)
  }

  const renderListItems = (items) => {
    return items.map((item) => (
      <React.Fragment key={item.id}>
        <ListItemButton
          className="testCaseDetailsTabContentStepsItem"
          onClick={() => handleItemClick(item)}
          sx={{
            border: item.result === 'success' ? '1px solid #2D4356' : '1px solid #DDD',
            borderRadius: '4px',
            mb: 0.5,
            backgroundColor: activeStep === item.id ? 'rgba(69, 116, 210, 0.1)' : 'transparent',
          }}
        >
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              style: { color: item.result === 'success' ? 'inherit' : '#DDD' },
            }}
          />
          {item.children.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleToggle(item)
              }}
              sx={{ mr: -0.5 }}
            >
              {open[item.id] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </ListItemButton>
        {item.children.length > 0 && (
          <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 2 }}>
              {renderListItems(item.children)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ))
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
    >
      {renderListItems(steps)}
    </List>
  )
}

export default Steps
