import { useRef } from 'react'
import { Box, Modal, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined'
import { PanZoom } from 'react-easy-panzoom'

const FailedLoginStepScreenshotModal = ({ openStepModal, handleStepModalClose, selectedFailedLoginStep }) => {
  const panZoomRef = useRef(null)

  const handleZoomIn = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomIn(5)
    }
  }

  const handleZoomOut = () => {
    if (panZoomRef.current) {
      panZoomRef.current.zoomOut(3.77)
    }
  }

  const handleReset = () => {
    if (panZoomRef.current) {
      panZoomRef.current.reset()
    }
  }

  return (
    <Modal
      open={openStepModal}
      onClose={handleStepModalClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '90%',
          maxWidth: '1200px',
          maxHeight: '90%',
          bgcolor: 'background.paper',
          p: '40px 40px 16px 40px',
          overflow: 'hidden',
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={handleStepModalClose}
          sx={{ position: 'absolute', top: 3, right: 1, zIndex: 100, color: '#2D4356' }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            border: '1px solid #2D4356',
            overflow: 'hidden',
          }}
        >
          <PanZoom ref={panZoomRef} style={{ width: '100%', height: '100%' }}>
            <Box sx={{ lineHeight: 0 }}>
              { selectedFailedLoginStep?.imgUrl ?
                <img
                  src={selectedFailedLoginStep.imgUrl}
                  alt="Section Screenshot"
                  style={{
                    width: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    pointerEvents: 'none',
                  }}
                /> : null
              }
            </Box>
          </PanZoom>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <IconButton className="zoomIconButton" onClick={handleZoomIn}>
            <AddIcon />
          </IconButton>
          <IconButton className="zoomIconButton" onClick={handleZoomOut}>
            <RemoveIcon />
          </IconButton>
          <IconButton className="zoomIconButton" onClick={handleReset}>
            <BlockOutlinedIcon />
          </IconButton>
        </Stack>
      </Box>
    </Modal>
  )
}

export default FailedLoginStepScreenshotModal
