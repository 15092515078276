import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useProject } from '../context/ProjectContext'
// import PageService from '../services/PageService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

const mockTestRun = {
  id: '1',
  testCaseName: 'Test Case #1',
  name: 'Test Run #11',
  pageUrl: 'https://google.com',
  pageLabel: 'Google - Home',
  videoUrl: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
  steps: [
    {
      id: 'step_1',
      label: 'Item 1',
      result: 'success',
      breakpoint: 0,
      children: [
        {
          id: 'step_2',
          label: 'Item 2',
          result: 'failed',
          breakpoint: 3,
          children: [
            {
              id: 'step_3',
              label: 'Item 3',
              result: 'success',
              breakpoint: 5,
              children: [
                {
                  id: 'step_4',
                  label: 'Item 4',
                  result: 'success',
                  breakpoint: 7,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'step_5',
      label: 'Item 5',
      result: 'success',
      breakpoint: 11,
      children: [
        {
          id: 'step_6',
          label: 'Item 6',
          result: 'success',
          breakpoint: 13,
          children: [],
        },
      ],
    },
    {
      id: 'step_7',
      label: 'Item 7',
      result: 'success',
      breakpoint: 14,
      children: [],
    },
  ]
}

const TestRun = () => {
  const { id } = useParams()
  const { selectedProject } = useProject()
  const [testRun, setTestRun] = useState(null)

  useEffect(() => {
    const getTestRun = async () => {
      try {
        // const testRus = await PageService.getTestRunById(id)
        // setTestRun(testRun)
        setTestRun(mockTestRun)
      } catch (error) {
        console.error('Error fetching test run:', error)
      }
    }

    getTestRun()
  }, [id, selectedProject?.id])

  return (
    <Box className="testRun">
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} ${testRun?.pageLabel} ${testRun?.name} for ${testRun?.testCaseName}`}</title>
      </Helmet>
      <Header title={testRun?.pageLabel}/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className="testRunContentContainer">
          {`${testRun?.name} for ${testRun?.testCaseName}`}
        </Box>
      </Box>
    </Box>
  )
}

export default TestRun