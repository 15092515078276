import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Card, CardContent } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const FailedLoginDetailsAccordion = ({ projectLoginData, handleStepModalOpen, selectedFailedLoginStep, setSelectedFailedLoginStep }) => {
  const steps = [
    {
      id: '1',
      imgUrl: projectLoginData.before_login_screenshot
    },
    {
      id: '2',
      imgUrl: projectLoginData.after_login_screenshot
    }
  ]

  const handleStepClick = (step) => {
    setSelectedFailedLoginStep(step)
  }

  return (
    <Box className="failedLoginStepsAccordionContainer">
      <Accordion defaultExpanded sx={{ borderRadius: '8px !important', boxShadow: 'none' }}>
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}>
          <Typography>Latest failed login details</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Box sx={{ width: '20%', height: '50vh' }}>
            <Card className="accordionDetailsSectionsContainer">
              <CardContent sx={{ minWidth: '100%', p: '4px 8px 8px 8px !important' }}>
                <Typography variant="h6" sx={{ height: '32px' }}>Login steps</Typography>
                <Box className="accordionDetailsSectionsContent">
                  {steps.map((step) => (
                    <Button
                      key={step.id}
                      className="accordionDetailsSectionsContentButton"
                      onClick={() => handleStepClick(step)}
                      sx={{ backgroundColor: selectedFailedLoginStep.id === step.id ? 'primary.medium' : 'transparent' }}
                    >
                      {step.id === '1' ? 'Submit login state' : 'Failed login navigation'}
                    </Button>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card onClick={handleStepModalOpen} sx={{ boxShadow: 'none', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
              <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: '8px !important' }}>
                <Box sx={{ position: 'relative', lineHeight: 0 }}>
                  <img
                    className="accordionDetailsImage"
                    src={selectedFailedLoginStep.imgUrl}
                    alt="Step Screenshot"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default FailedLoginDetailsAccordion