import { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, InputAdornment, Alert, LinearProgress, Button, Tooltip } from '@mui/material'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined'
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { socketEventEmitter } from '../../../socket'
import { useProject } from '../../../context/ProjectContext'
import ProjectService from '../../../services/ProjectService'
import LoginPageBlocksAccordion from './LoginPageBlocksAccordion'
import FailedLoginDetailsAccordion from './FailedLoginDetailsAccordion'
import FailedLoginStepScreenshotModal from './FailedLoginStepScreenshotModal'
import ElementsScreenshotModal from './ElementsScreenshotModal'

const LoginTab = ({
  url,
  setUrl,
  urlError,
  handleUrlChange,
  loginFormId,
  setLoginFormId,
  login,
  setLogin,
  loginError,
  handleLoginChange,
  isPasswordExist,
  setIsPasswordExist,
  isEditingPassword,
  newPassword,
  handleNewPasswordChange,
  handleClosePassword,
  handleSavePassword,
  handleEditPassword,
  password,
  handlePasswordChange,
  passwordError,
  elementsIdRoles,
  setElementsIdRoles,
  sections,
  setSections,
  selectedSection,
  setSelectedSection,
  selectedElement,
  setSelectedElement,
  setRescanLoginPageLoading,
  setOpenLoginRequiredModal
}) => {
  const { selectedProject } = useProject()

  const [projectLoginData, setProjectLoginData] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const [openStepModal, setOpenStepModal] = useState(false)
  const [selectedFailedLoginStep, setSelectedFailedLoginStep] = useState(null)

  const [openElementModal, setOpenElementModal] = useState(false)

  useEffect(() => {
    const getProjectLoginData = async () => {
      try {
        const projectLoginData = await ProjectService.getProjectLoginDataById(selectedProject.id)
        setProjectLoginData(projectLoginData)
        setUrl(projectLoginData.login_page_url)
        setLoginFormId(projectLoginData.login_form_id)
        setLogin(projectLoginData.login)
        setIsPasswordExist(projectLoginData?.is_password_exist || projectLoginData.password)
        if (projectLoginData?.before_login_screenshot) {
          setSelectedFailedLoginStep({
            id: '1',
            imgUrl: projectLoginData.before_login_screenshot
          })
        }
        if (projectLoginData.elements) {
          setElementsIdRoles(projectLoginData.elements.map(element => {
            return {
              id: element.id,
              role: element.role
            }
          }))
          setSections([{
            id: projectLoginData.id,
            img_storage_link: projectLoginData.img_storage_link,
            elements: projectLoginData.elements
          }])
          setSelectedSection({
            id: projectLoginData.id,
            img_storage_link: projectLoginData.img_storage_link,
            elements: projectLoginData.elements
          })
        }
      } catch (error) {
        console.log(error?.response?.data?.error)
      }
    }

    getProjectLoginData()

    const handleTaskCompleted = async (data) => {
      if (data.type === "Login Elements Processing" || data.type === "Login Test") {
        getProjectLoginData()
        setRescanLoginPageLoading(false)
      }
    }

    const handleTaskFailed = async (data) => {
      if (data.type === "Login Elements Processing" || data.type === "Login Test") {
        setProjectLoginData(null)
        getProjectLoginData()
        setRescanLoginPageLoading(false)
      }
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
  // eslint-disable-next-line
  }, [selectedProject.id])

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prev) => !prev)
  }

  const handleStepModalOpen = () => setOpenStepModal(true)
  const handleStepModalClose = () => setOpenStepModal(false)

  const handleElementModalOpen = () => setOpenElementModal(true)
  const handleElementModalClose = () => setOpenElementModal(false)

  return (
    <Box className="projectLoginSetupContentContainer">
      <Box className="projectLoginSetupCredentialsContainer">
        <Box className="projectLoginSetupCredentialsItem">
          <LinkOutlinedIcon sx={{ mr: '8px' }} />
          <Typography className="projectLoginSetupCredentialsItemLabel">Login Page URL*:</Typography>
          <TextField
            size='small'
            className="projectLoginSetupCredentialsItemInput"
            placeholder="Login page URL"
            value={url}
            onChange={(e) => handleUrlChange(e.target.value)}
            required
            error={urlError}
            helperText={urlError ? "Required field" : null}
            autoComplete="new-url"
          />
          <Button onClick={() => setOpenLoginRequiredModal(true)} variant="contained" color="secondary" sx={{ width: '144px', textTransform: 'none', ml: 2, mr: 1 }}>
            Re-setup login
          </Button>
          <Tooltip
            title="If login page element mapping fails due to 'anti-bot' protection, then you can try to temporarily remove the protection (if you own the website) and click the re-setup login button."
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'primary.main'
                },
              },
            }}
          >
            <IconButton size="small" sx={{ p: 0 }}>
              <InfoOutlinedIcon fontSize="small" color='primary'/>
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="projectLoginSetupCredentialsItem">
          <CodeOutlinedIcon sx={{ mr: '8px' }} />
          <Typography className="projectLoginSetupCredentialsItemLabel">Login Form ID:</Typography>
          <TextField
            size='small'
            className="projectLoginSetupCredentialsItemInput"
            placeholder="e.g., 'login-form-id'"
            value={loginFormId}
            onChange={(e) => setLoginFormId(e.target.value)}
            autoComplete="new-login-form-id"
          />
        </Box>
        <Box className="projectLoginSetupCredentialsItem">
          <PortraitOutlinedIcon sx={{ mr: '8px' }} />
          <Typography className="projectLoginSetupCredentialsItemLabel">Login*:</Typography>
          <TextField
            size='small'
            className="projectLoginSetupCredentialsItemInput"
            placeholder="Login or Email"
            value={login ? login : ''}
            onChange={(e) => handleLoginChange(e.target.value)}
            required
            autoComplete="new-login"
            error={loginError}
            helperText={loginError ? "Required field" : null}
          />
        </Box>
        <Box className="projectLoginSetupCredentialsItem">
          <PasswordOutlinedIcon sx={{ mr: '8px' }} />
          <Typography className="projectLoginSetupCredentialsItemLabel">Password*:</Typography>
          { isPasswordExist ?
            isEditingPassword ?
              <Box sx={{ display: 'flex', alignItems: 'center', height: '72px'  }}>
                <TextField
                  placeholder="New Password"
                  type={showNewPassword ? 'text' : 'password'}
                  size='small'
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  sx={{ width: '300px', height: '40px', m: 0, fontSize: '1.25rem' }}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleNewPasswordVisibility} edge="end" size='small'>
                          { newPassword.length ?
                            showNewPassword ? <Visibility color='primary'/> : <VisibilityOff color='primary'/> :
                            null
                          }
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton onClick={handleSavePassword} sx={{ ml: 0.5 }}>
                  <CheckSharpIcon color='primary'/>
                </IconButton>
                <IconButton onClick={handleClosePassword} sx={{ ml: 0.5 }}>
                  <CloseOutlinedIcon color='primary'/>
                </IconButton>
              </Box> :
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ height: '16px' }}>******</span>
                <IconButton onClick={handleEditPassword} sx={{ ml: 0.5 }}>
                  <EditOutlinedIcon color='primary'/>
                </IconButton>
              </Box>
            :
            <TextField
              size='small'
              className="projectLoginSetupCredentialsItemInput"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              required
              autoComplete="new-password"
              error={passwordError}
              helperText={passwordError ? "Required field" : null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end" size='small'>
                      { password.length ?
                        showPassword ? <Visibility color='primary'/> : <VisibilityOff color='primary'/> :
                        null
                      }
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}
        </Box>
      </Box>
      { !projectLoginData?.is_verified && projectLoginData?.before_login_screenshot && projectLoginData?.after_login_screenshot ?
        <FailedLoginDetailsAccordion
          projectLoginData={projectLoginData}
          handleStepModalOpen={handleStepModalOpen}
          selectedFailedLoginStep={selectedFailedLoginStep}
          setSelectedFailedLoginStep={setSelectedFailedLoginStep}
        /> :
        null
      }
      { projectLoginData?.elements ?
        <>
          <Box>
            <Alert className="projectLoginSetupAlert" variant="outlined" severity="info">
              <strong>Important: </strong>choose the elements that correspond to the provided login credentials.
            </Alert>
          </Box>
          <LoginPageBlocksAccordion
            elementsIdRoles={elementsIdRoles}
            setElementsIdRoles={setElementsIdRoles}
            sections={sections}
            setSections={setSections}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            handleElementModalOpen={handleElementModalOpen}
          />
        </> :
        <Box sx={{ mt: 4 }}>
          <Typography mb={1}>Login elements processing...</Typography>
          <LinearProgress color="secondary" />
        </Box>
      }
      <FailedLoginStepScreenshotModal openStepModal={openStepModal} handleStepModalClose={handleStepModalClose} selectedFailedLoginStep={selectedFailedLoginStep}/>
      <ElementsScreenshotModal openElementModal={openElementModal} handleElementModalClose={handleElementModalClose} selectedSection={selectedSection} selectedElement={selectedElement}/>
    </Box>
  )
}

export default LoginTab