import { TableHead, TableRow, TableCell, Checkbox, Box, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'

const HierarchicalTableHeader = ({
  isHeaderCheckboxChecked,
  includedIntoScanningTotal,
  anchorEl,
  open,
  handleCheckAll,
  handleClickCheckAllDropdown,
  handleCloseCheckAllDropdown,
  handleCheckAllHierarchicalPages,
  handleUncheckAllHierarchicalPages
}) => {

  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            position: 'sticky',
            top: 0,
            zIndex: 2,
            borderBottom: '2px solid #D7E2EC',
          },
        }}
      >
        <TableCell sx={{ width: '100px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              sx={{ ml: '8px' , p: 0 }}
              checked={isHeaderCheckboxChecked}
              onChange={handleCheckAll}
              onClick={(event) => event.stopPropagation()}
            />
            <IconButton
              onClick={handleClickCheckAllDropdown}
              size="small"
              sx={{ p: 0 }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseCheckAllDropdown}
            >
              <MenuItem onClick={handleCheckAllHierarchicalPages} sx={{ minWidth: '100px' }}>All</MenuItem>
              <MenuItem onClick={handleUncheckAllHierarchicalPages}>None</MenuItem>
            </Menu>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ p: '12px 8px 12px 0' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Page URL
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '178px', minWidth: '178px', p: '12px 8px 12px 0' }}>
          <Box className="tableCellContentWithBorder">
            Last Discovery Date
            <Tooltip
              title="The date when the page was first detected and added to the website map."
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'primary.main'
                  },
                },
              }}
            >
              <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                <InfoOutlinedIcon fontSize="small" color='primary'/>
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '132px', minWidth: '132px', p: '12px 8px 12px 0' }}>
          <Box className="tableCellContentWithBorder">
            Last Scan Date
            <Tooltip
              title="The date when the page was analyzed by AI for UI issues."
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'primary.main'
                  },
                },
              }}
            >
              <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                <InfoOutlinedIcon fontSize="small" color='primary'/>
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '130px', minWidth: '130px', p: '12px 8px 12px 0' }}>
          <Box className="tableCellContentWithBorder">
            Passed Tests
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title="The number of issue types based on your active project settings selections, that was not found on this page."
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'primary.main'
                    },
                  },
                }}
              >
                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                  <InfoOutlinedIcon fontSize="small" color='primary'/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '116px', minWidth: '116px', p: '12px 8px 12px 0' }}>
          <Box className="tableCellContentWithBorder">
            Failed Tests
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '194px', minWidth: '194px', p: '12px 8px 12px 0' }}>
          <Box className="tableCellContentWithBorder" mr={1}>
            <Box>Included into Scanning</Box>
            <Box className="includedIntoScanningBadge" sx={{ backgroundColor: '#198754' }}>{includedIntoScanningTotal}</Box>
            <Tooltip
              title="Indicates if this page will be scanned when you click the 'Scan Included Pages' button."
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'primary.main'
                  },
                },
              }}
            >
              <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                <InfoOutlinedIcon fontSize="small" color='primary'/>
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default HierarchicalTableHeader