import { Box, Select, MenuItem, FormControl, InputLabel, IconButton, Tooltip } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
import LoadingButton from '@mui/lab/LoadingButton'

const UiTestingActions = ({ loginRequired, allResolutionPages, selectedScreen, handleScreenChange, isScanLoading, handleScanPage, handleOpenProjectSettings, handleOpenProjectLoginSettings }) => {
  const isScreenSizeAvailable = (screenSize) => {
    return allResolutionPages.some(page => page.screen_size === screenSize)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControl variant="outlined" sx={{ minWidth: 120 }}>
        <InputLabel>Screen</InputLabel>
        <Select
          size="small"
          value={selectedScreen}
          onChange={handleScreenChange}
          label="Screen"
          sx={{ width: '160px' }}
        >
          <MenuItem value="desktop" disabled={!isScreenSizeAvailable('desktop')}>
            Desktop
          </MenuItem>
          <MenuItem value="tablet" disabled={!isScreenSizeAvailable('tablet')}>
            Tablet
          </MenuItem>
          <MenuItem value="mobile" disabled={!isScreenSizeAvailable('mobile')}>
            Mobile
          </MenuItem>
        </Select>
      </FormControl>
      <Tooltip
        title="To change resolution and other scanning parameters, go to Project Settings."
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'primary.main'
            },
          },
        }}
      >
        <IconButton onClick={handleOpenProjectSettings}>
          <SettingsOutlinedIcon color='primary' />
        </IconButton>
      </Tooltip>
      { loginRequired ?
        <Tooltip
          title="To change login settings, go to Login Settings."
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ height: '40px' }} onClick={handleOpenProjectLoginSettings}>
            <RoomPreferencesIcon color='primary' />
          </IconButton>
        </Tooltip> :
        null }
      <LoadingButton
        id='scanPageButton'
        variant="contained"
        color="secondary"
        onClick={handleScanPage}
        loading={isScanLoading}
        disabled={isScanLoading}
        loadingIndicator="Scanning…"
        sx={{ width: '160px', height: '38px', textTransform: 'none', opacity: isScanLoading ? 0.8 : 1, ml: 2 }}
      >
        Scan Page
      </LoadingButton>
    </Box>
  )
}

export default UiTestingActions