import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, IconButton, Tooltip } from '@mui/material'
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { socketEventEmitter } from '../../../../socket'
import { useProject } from '../../../../context/ProjectContext'
import { useNotification } from '../../../../context/NotificationContext'
import ScanService from '../../../../services/ScanService'
import UiTestingActions from './UiTestingActions'
import FullPageAccordion from './FullPageAccordion'
import PageBlocksAccordion from './PageBlocksAccordion'
import FullScreenshotModal from './FullScreenshotModal'
import BlockScreenshotModal from './BlockScreenshotModal'
import StatsSummary from '../../../StatsSummary'
import { formatDate } from '../../../../utils/formatDate'
import { sortIssues } from '../../../../utils/sortIssues'

const UiTestingTab = ({ page, allResolutionPages, discoveryPageToggler, setDiscoveryPageToggler }) => {
  const navigate = useNavigate()
  const { selectedProject } = useProject()
  const showNotification = useNotification()

  const [open, setOpen] = useState(false)
  const [openBlockModal, setOpenBlockModal] = useState(false)
  const [selectedSection, setSelectedSection] = useState(null)
  const [selectedIssue, setSelectedIssue] = useState(null)
  const [selectedScreen, setSelectedScreen] = useState('desktop')
  const [fullPageBlock, setFullPageBlock] = useState(null)
  const [pageBlocks, setPageBlocks] = useState(null)
  const [isScanLoading, setIsScanLoading] = useState(false)

  useEffect(() => {
    if (page && selectedProject?.id && page.page.project_id !== selectedProject.id) {
      navigate(`/pages`)
    }
    setIsScanLoading(false)
    setFullPageBlock(null)
    setPageBlocks(null)
    setSelectedSection(null)
    setSelectedIssue(null)
    if (page) {
      setSelectedScreen(page.page.screen_size)
    }

    if (page?.blocks.length) {
      setFullPageBlock(page.blocks[0])

      if (page?.blocks.length > 1) {
        const blocks = page.blocks.filter((pageBlock, index) => index > 0)
        setPageBlocks(blocks)
        setSelectedSection(blocks[0])
        if (blocks[0].Issues.length) {
          setSelectedIssue(sortIssues(blocks[0].Issues)[0])
        }
      } else if (page?.blocks.length === 1) {
        const blocks = page.blocks
        setPageBlocks(blocks)
        setSelectedSection(blocks[0])
        if (blocks[0].Issues.length) {
          setSelectedIssue(sortIssues(blocks[0].Issues)[0])
        }
      }
    }

    const handleTaskCompleted = async (data) => {
      if (data.pageId === page?.page.id) {
        setDiscoveryPageToggler(!discoveryPageToggler)
      }
    }

    const handleTaskFailed = async (data) => {
      console.log('(Overview Tab) Task failed:', data)
      setIsScanLoading(false)
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
  // eslint-disable-next-line
  }, [selectedProject?.id, page])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleBlockModalOpen = () => setOpenBlockModal(true)
  const handleBlockModalClose = () => setOpenBlockModal(false)

  const handleScreenChange = (event) => {
    setSelectedScreen(event.target.value)
    if (event.target.value !== page.page.screen_size) {
      const targetPage = allResolutionPages.find(page => page.screen_size === event.target.value)
      navigate(`/pages/${targetPage.id}`)
    }
  }

  const handleScanPage = async () => {
    const pageIds = [page.page.id]
    setIsScanLoading(true)
    showNotification('UI Scan has started', 'info', 'Learn more', navigate)
    await ScanService.scanPages({ pageIds })
  }

  const handleOpenProjectSettings = () => {
    navigate(`/project/${page.page.project_id}`, { state: { historyPageId: page.page.id } })
  }

  const handleOpenProjectLoginSettings = () => {
    navigate(`/project/${page.page.project_id}`, { state: { historyPageId: page.page.id, activeTab: '2' }})
  }

  const handleOpenLink = () => {
    window.open(page?.page?.url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <StatsSummary
          title="Last Scan Date"
          value={ page?.page.last_scanned_at ?
            formatDate(page.page.last_scanned_at) :
            'Unscanned'
          }
          color="primary"
        />
        <StatsSummary
          title="Total Scrolls"
          value={ page?.page.last_scanned_at ?
            page.blocks.length > 1 ?
              page.blocks.length - 1 :
              1 :
            'N/A'
          }
          color="primary"
        />
        <StatsSummary
          title="Total Issues"
          value={ page?.page.issue_count ?
            page.page.issue_count :
            'No issues detected'
          }
          color={ page?.page.issue_count ?
            "error" :
            'primary'
          }
        />
      </Box>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" color="primary" sx={{ m: '8px 0' }}>{page?.page?.label}</Typography>
        <UiTestingActions
          loginRequired={selectedProject?.login_required}
          allResolutionPages={allResolutionPages}
          selectedScreen={selectedScreen}
          handleScreenChange={handleScreenChange}
          isScanLoading={isScanLoading}
          handleScanPage={handleScanPage}
          handleOpenProjectSettings={handleOpenProjectSettings}
          handleOpenProjectLoginSettings={handleOpenProjectLoginSettings}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="body1" color="primary">{page?.page?.url}</Typography>
        <Tooltip
          title="Open in a new tab"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ ml: 0.5 }} onClick={handleOpenLink}>
            <OpenInNewIcon color='primary'/>
          </IconButton>
        </Tooltip>
      </Box>
      { page?.page?.summary ? <Typography variant="body1" color="primary" sx={{ mb: 2 }}>Summary: {page.page.summary}</Typography> : null }
      { page?.page.last_scanned_at && !page.page.issue_count ?
        <Typography variant="body1" color="success.main" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          Great job! No UI issues were found on this page. Your UI is looking flawless!
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 0.5, mb: 0.5, fontSize: '20px' }} />
        </Typography> :
        null
      }
      { !fullPageBlock && !pageBlocks && !page?.page?.last_scanned_at && !page?.page?.server_response ?
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: 'rgba(0, 0, 0, 0.6)',
            mt: '100px'
          }}
        >
          <PageviewOutlinedIcon sx={{ fontSize: 92, mb: 2, color: 'primary.main' }} />
          <Typography variant="body1" sx={{ maxWidth: '650px' }}>
            To check UI issues on this page, please click the &apos;<span style={{ fontWeight: 600 }}>Scan Page</span>&apos; button.<br/>
            To adjust scanning settings (e.g., resolution, browsers), go to&nbsp;
            <span className="noDataAvailableLink" onClick={handleOpenProjectSettings}>
              Project Settings
            </span>.
          </Typography>
        </Box> : null }
      { fullPageBlock && <FullPageAccordion handleOpen={handleOpen} fullPageBlock={fullPageBlock}/> }
      { pageBlocks &&
        <PageBlocksAccordion
          pageBlocks={pageBlocks}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
          handleBlockModalOpen={handleBlockModalOpen}
        />
      }
      <FullScreenshotModal open={open} handleClose={handleClose} fullPageBlock={fullPageBlock}/>
      <BlockScreenshotModal openBlockModal={openBlockModal} handleBlockModalClose={handleBlockModalClose} selectedSection={selectedSection} selectedIssue={selectedIssue}/>
    </Box>
  )
}

export default UiTestingTab