import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Typography, Button, CircularProgress, Tab } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useProject } from '../context/ProjectContext'
import { useNotification } from '../context/NotificationContext'
import ProjectService from '../services/ProjectService'
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import GeneralTab from "../components/projectSettings/general/GeneralTab"
import LoginTab from "../components/projectSettings/login/LoginTab"
import MakeLoginRequiredModal from "../components/modals/MakeLoginRequiredModal"
import { validateProjectName } from '../utils/validateForm'

const ProjectSettings = () => {
  const { selectedProject } = useProject()
  const navigate = useNavigate()
  const location = useLocation()
  const showNotification = useNotification()

  const [activeTabValue, setActiveTabValue] = useState('1')
  const [historyPageId, setHistoryPageId] = useState(null)
  const [project, setProject] = useState(null)
  const [projectName, setProjectName] = useState('')
  const [projectNameError, setProjectNameError] = useState('')
  const [allIssueTypes, setAllIssueTypes] = useState([])
  const [projectIssueTypes, setProjectIssueTypes] = useState(["Server response error (e.g., 401, 403)"])
  const [projectIssueTypesError, setProjectIssueTypesError] = useState(false)
  const [saveProjectLoading, setSaveProjectLoading] = useState(false)
  const [environment, setEnvironment] = useState("Staging")
  const [resolutions, setResolutions] = useState({ desktop: false, tablet: false, mobile: false })
  const [loginRequired, setLoginRequired] = useState(false)
  const [isLoginDataExist, setIsLoginDataExist] = useState(false)
  const [openLoginRequiredModal, setOpenLoginRequiredModal] = useState(false)

  const [url, setUrl] = useState('')
  const [urlError, setUrlError] = useState(false)
  const [loginFormId, setLoginFormId] = useState('')
  const [login, setLogin] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [isPasswordExist, setIsPasswordExist] = useState(null)
  const [isEditingPassword, setIsEditingPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [sections, setSections] = useState([])
  const [elementsIdRoles, setElementsIdRoles] = useState([])
  const [selectedSection, setSelectedSection] = useState(null)
  const [selectedElement, setSelectedElement] = useState(null)
  const [rescanLoginPageLoading, setRescanLoginPageLoading] = useState(false)

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const allIssueTypesData = await ProjectService.getIssueTypes()
        setAllIssueTypes(allIssueTypesData)
        const projectData = await ProjectService.getProjectById(selectedProject.id)
        setLoginRequired(projectData?.login_required)
        if (!projectData?.login_required) {
          setActiveTabValue('1')
        }
        setIsLoginDataExist(projectData?.is_auth_data)
        setProject(projectData)
        setProjectName(projectData.name)
        setEnvironment(projectData.environment)
        setResolutions({
          desktop: projectData.screen_sizes.includes('desktop'),
          tablet: projectData.screen_sizes.includes('tablet'),
          mobile: projectData.screen_sizes.includes('mobile'),
        })
        const projectIssueTypesData = allIssueTypesData.filter(type => projectData.issue_class_ids.includes(type.id)).map(type => type.description)
        setProjectIssueTypes(projectIssueTypesData)
      } catch (error) {
        console.log(error)
      }
    }

    getProjectData()
  }, [selectedProject.id])

  useEffect(() => {
    if (location.state?.historyPageId) {
      setHistoryPageId(location.state.historyPageId)
    }
    if (location.state?.activeTab) {
      setActiveTabValue(location.state?.activeTab)
    }
  }, [location.state])

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value)
    if (projectNameError) {
      setProjectNameError('')
    }
  }

  const handleEnvironmentChange = (newValue) => {
    setEnvironment(newValue)
  }

  const handleResolutionsChange = (event) => {
    setResolutions({
      ...resolutions,
      [event.target.name]: event.target.checked,
    })
  }

  const handleProjectIssueTypesChange = (event) => {
    const selectedTypes = event.target.value

    if (!selectedTypes.includes("Server response error (e.g., 401, 403)")) {
      setProjectIssueTypes(["Server response error (e.g., 401, 403)", ...selectedTypes])
    } else {
      setProjectIssueTypes(selectedTypes)
    }

    setProjectIssueTypesError(selectedTypes.length === 0)
  }

  const handleChangeActiveTab = (event, newValue) => {
    setActiveTabValue(newValue)
  }

  const handleUrlChange = (value) => {
    setUrl(value)
    setUrlError(false)
  }

  const handleLoginChange = (value) => {
    setLogin(value)
    setLoginError(false)
  }

  const handlePasswordChange = (value) => {
    setPassword(value)
    setPasswordError(false)
  }

  const handleEditPassword = () => {
    setIsEditingPassword(true)
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value)
  }

  const handleSavePassword = () => {
    setIsEditingPassword(false)
  }

  const handleClosePassword = () => {
    setIsEditingPassword(false)
    setNewPassword('')
  }

  const handleLoginRequiredChange = (e) => {
    const isChecked = e.target.checked
    if (isChecked && !isLoginDataExist) {
      setOpenLoginRequiredModal(true)
    }
    if (isChecked && isLoginDataExist) {
      setLoginRequired(true)
    }
    if (!isChecked) {
      setLoginRequired(false)
    }
  }

  const prepareUpdatedProjectLoginData = () => {
    const LoginData = {
      loginPageUrl: url,
      login,
    }

    if (loginFormId) {
      LoginData.loginFormId = loginFormId
    }

    if (isPasswordExist && newPassword) {
      LoginData.password = newPassword
    } else if (password) {
      LoginData.password = password
    }

    if (elementsIdRoles.length) {
      LoginData.elementsIdRoles = elementsIdRoles.filter(element => element.role)
    }

    return LoginData
  }

  const validateElementsIdRoles = () => {
    const requiredRoles = ["Login input", "Password input", "Sign-in button"]
    return requiredRoles.every(role =>
      elementsIdRoles.some(item => item.role === role)
    )
  }

  const handleCloseLoginRequiredData = () => {
    setOpenLoginRequiredModal(false)
  }

  const handleSaveLoginRequiredData = async (loginPageUrl, loginFormId) => {
    try {
      await ProjectService.rescanProjectLoginData(selectedProject.id, { loginPageUrl, loginFormId })
    } catch (error) {
      console.log(error)
    } finally {
      setLoginRequired(true)
      setOpenLoginRequiredModal(false)
    }
  }

  const handleRescanLoginPage = async () => {
    setRescanLoginPageLoading(true)
    setElementsIdRoles([])
    try {
      await ProjectService.rescanProjectLoginData(selectedProject.id, { loginPageUrl: url, loginFormId })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    if (historyPageId) {
      navigate(`/pages/${historyPageId}`)
    } else {
      navigate(`/`)
    }
  }

  const handleGeneralSettingsSave = async () => {
    setSaveProjectLoading(true)

    if (!validateProjectName(projectName)) {
      setProjectNameError('Invalid name.')
      setSaveProjectLoading(false)
      return
    }

    const issueClassIds = allIssueTypes.filter(type => projectIssueTypes.includes(type.description)).map(type => type.id)
    const updatedScreenSizes = Object.keys(resolutions).filter(res => resolutions[res])

    try {
      const updatedProjectData = await ProjectService.updateProject(selectedProject.id, projectName, environment, issueClassIds, updatedScreenSizes, loginRequired)
      setProject(updatedProjectData)

      if (historyPageId) {
        navigate(`/pages/${historyPageId}`)
      } else {
        navigate(`/`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSaveProjectLoading(false)
    }
  }

  const handleLoginSettingsSave = async () => {
    if (validateElementsIdRoles()) {
      setSaveProjectLoading(true)

      let isValid = true

      if (!url) {
        setUrlError(true)
        isValid = false
      }
      if (!login) {
        setLoginError(true)
        isValid = false
      }
      if (!isPasswordExist && !password) {
        setPasswordError(true)
        isValid = false
      }

      if (!isValid) {
        setSaveProjectLoading(false)
        return
      }

      try {
        const updatedProjectLoginData = prepareUpdatedProjectLoginData()
        await ProjectService.updateProjectLoginData(selectedProject.id, updatedProjectLoginData)

        if (historyPageId) {
          navigate(`/pages/${historyPageId}`)
        } else {
          navigate(`/`)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setSaveProjectLoading(false)
      }
    } else {
      showNotification("You should choose 'Login Input', 'Password Input' and 'Sign-In Button' elements of you login page", "error", "", null)
    }
  }

  return (
    <Box>
      <Helmet>
        <title>{`Treegress - ${project?.name} Project Settings`}</title>
      </Helmet>
      <Header title={project ? project?.name : "Project"} />
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        {project && (
          <Box className="projectSettings">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">Project Settings</Typography>
              <Box>
                { loginRequired && activeTabValue === "2" ?
                  rescanLoginPageLoading ?
                    <LoadingButton
                      loading
                      variant="contained"
                      color="secondary"
                      loadingIndicator={<CircularProgress color="white" size={16} />}
                      sx={{ width: '170px', textTransform: 'none', mr: 2 }}
                    >
                      Rescan login page
                    </LoadingButton> :
                    <Button onClick={handleRescanLoginPage} variant="contained" color="secondary" sx={{ width: '170px', textTransform: 'none', mr: 2 }}>
                      Rescan login page
                    </Button>
                  : null }
                <Button onClick={handleCancel} variant="contained" color="secondary" sx={{ width: '80px', textTransform: 'none', mr: 2 }}>
                  Cancel
                </Button>
                { saveProjectLoading ?
                  <LoadingButton
                    loading
                    variant="contained"
                    color="secondary"
                    loadingIndicator={<CircularProgress color="white" size={16} />}
                    sx={{ width: '80px', textTransform: 'none', mr: 2 }}
                  >
                    Save
                  </LoadingButton> :
                  activeTabValue === "1" ?
                    <Button onClick={handleGeneralSettingsSave} variant="contained" color="secondary" sx={{ width: '80px', textTransform: 'none', mr: 2 }}>
                      Save
                    </Button> :
                    elementsIdRoles.length ?
                      <Button onClick={handleLoginSettingsSave} variant="contained" color="secondary" sx={{ width: '80px', textTransform: 'none', mr: 2 }}>
                        Save
                      </Button> :
                      <Button disabled variant="contained" color="secondary" sx={{ width: '80px', textTransform: 'none', mr: 2 }}>
                        Save
                      </Button>
                }
              </Box>
            </Box>
            <TabContext value={activeTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'rgba(45,67,86,0.5)' }}>
                <TabList onChange={handleChangeActiveTab}>
                  <Tab label="General" value="1" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
                  <Tab label="Login" value="2" disabled={!loginRequired} sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: "16px 0" }}>
                <GeneralTab
                  project={project}
                  projectName={projectName}
                  handleProjectNameChange={handleProjectNameChange}
                  projectNameError={projectNameError}
                  environment={environment}
                  handleEnvironmentChange={handleEnvironmentChange}
                  resolutions={resolutions}
                  handleResolutionsChange={handleResolutionsChange}
                  allIssueTypes={allIssueTypes}
                  projectIssueTypes={projectIssueTypes}
                  projectIssueTypesError={projectIssueTypesError}
                  handleProjectIssueTypesChange={handleProjectIssueTypesChange}
                  loginRequired={loginRequired}
                  handleLoginRequiredChange={handleLoginRequiredChange}
                />
              </TabPanel>
              <TabPanel value="2" sx={{ p: "16px 0" }}>
                <LoginTab
                  url={url}
                  setUrl={setUrl}
                  urlError={urlError}
                  handleUrlChange={handleUrlChange}
                  loginFormId={loginFormId}
                  setLoginFormId={setLoginFormId}
                  login={login}
                  setLogin={setLogin}
                  loginError={loginError}
                  handleLoginChange={handleLoginChange}
                  isPasswordExist={isPasswordExist}
                  setIsPasswordExist={setIsPasswordExist}
                  isEditingPassword={isEditingPassword}
                  newPassword={newPassword}
                  handleNewPasswordChange={handleNewPasswordChange}
                  handleClosePassword={handleClosePassword}
                  handleSavePassword={handleSavePassword}
                  handleEditPassword={handleEditPassword}
                  password={password}
                  handlePasswordChange={handlePasswordChange}
                  passwordError={passwordError}
                  elementsIdRoles={elementsIdRoles}
                  setElementsIdRoles={setElementsIdRoles}
                  sections={sections}
                  setSections={setSections}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  selectedElement={selectedElement}
                  setSelectedElement={setSelectedElement}
                  setRescanLoginPageLoading={setRescanLoginPageLoading}
                  setOpenLoginRequiredModal={setOpenLoginRequiredModal}
                />
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </Box>
      <MakeLoginRequiredModal
        open={openLoginRequiredModal}
        onClose={handleCloseLoginRequiredData}
        onSave={handleSaveLoginRequiredData}
      />
    </Box>
  )
}

export default ProjectSettings