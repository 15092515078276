import { Box, Typography, TextField, FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox, MenuItem, Select, ListItemText, Alert, AlertTitle, Tooltip, IconButton, Switch } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import OpenInBrowserOutlinedIcon from '@mui/icons-material/OpenInBrowserOutlined'
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined'
import CustomSelectWithInput from '../../CustomSelectWithInput'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'

const GeneralTab = ({
  project,
  projectName,
  handleProjectNameChange,
  projectNameError,
  environment,
  handleEnvironmentChange,
  resolutions,
  handleResolutionsChange,
  allIssueTypes,
  projectIssueTypes,
  projectIssueTypesError,
  handleProjectIssueTypesChange,
  loginRequired,
  handleLoginRequiredChange
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <DriveFileRenameOutlineOutlinedIcon sx={{ mr: '8px' }} />
        <Typography sx={{ width: '180px', m: 0, fontSize: '1.25rem' }}>Project Name:</Typography>
        <TextField
          size='small'
          value={projectName}
          onChange={handleProjectNameChange}
          required
          error={!!projectNameError}
          helperText={projectNameError + ' '}
          sx={{ width: '300px', height: '40px', m: 0, fontSize: '1.25rem' }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <LinkOutlinedIcon sx={{ mr: '8px' }} />
        <Typography sx={{ width: '180px', m: 0, fontSize: '1.25rem' }}>Project URL:</Typography>
        <Typography sx={{ m: 0, fontSize: '1.25rem' }}>{project?.url}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <BuildCircleOutlinedIcon sx={{ mr: '8px' }} />
        <Typography sx={{ width: '180px', m: 0, fontSize: '1.25rem' }}>Environment:</Typography>
        <FormControl sx={{ minWidth: 120 }} size="small">
          <CustomSelectWithInput
            value={environment}
            handleEnvironmentChange={handleEnvironmentChange}
            defaultOptions={["Staging", "Production"]}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <OpenInBrowserOutlinedIcon sx={{ mr: '8px' }} />
        <Typography sx={{ width: '180px', m: 0, fontSize: '1.25rem' }}>Browsers:</Typography>
        <Typography sx={{ width: '200px', m: 0, fontSize: '1.25rem' }}>Chrome</Typography>
      </Box>
      <Alert className="projectSettingsAlert" variant="outlined" severity="info" sx={{ width: '626px', p: '8px' }}>
        <AlertTitle><strong>Important</strong></AlertTitle>
          Updates to the settings below will be applied during the next project page scan.
      </Alert>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <AspectRatioOutlinedIcon sx={{ mr: '8px' }} />
        <Typography sx={{ width: '180px', m: 0, fontSize: '1.25rem' }}>Resolutions:</Typography>
        <FormControl component="fieldset" variant="standard">
          <FormGroup sx={{ flexDirection: 'row' }}>
            <FormControlLabel
              control={
                <Checkbox disabled checked={resolutions.desktop} onChange={handleResolutionsChange} name="desktop" />
              }
              label="Desktop"
            />
            <FormControlLabel
              control={
                <Checkbox disabled checked={resolutions.tablet} onChange={handleResolutionsChange} name="tablet" />
              }
              label="Tablet"
            />
            <FormControlLabel
              control={
                <Checkbox disabled checked={resolutions.mobile} onChange={handleResolutionsChange} name="mobile" />
              }
              label="Mobile"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <ScienceOutlinedIcon sx={{ mr: 1 }} />
        <Typography sx={{ width: '180px', minWidth: '180px', m: 0, fontSize: '1.25rem' }}>UI Tests:</Typography>
        <FormControl error={projectIssueTypesError} sx={{ maxHeight: '40px' }}>
          <Select
            size='small'
            sx={{ width: '300px', height: '40px', textTransform: 'capitalize' }}
            multiple
            value={projectIssueTypes}
            onChange={handleProjectIssueTypesChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={{
              PaperProps: {
                onMouseDown: (event) => event.stopPropagation(),
              },
              autoFocus: false,
            }}
          >
            {allIssueTypes.map((type) => (
              <MenuItem key={type.id} value={type.description}>
                <Checkbox checked={projectIssueTypes.indexOf(type.description) > -1} disabled={type.description === "Server response error (e.g., 401, 403)"} />
                <ListItemText primary={<span style={{ textTransform: 'capitalize' }}>{type.description}</span>} />
              </MenuItem>
            ))}
          </Select>
          {projectIssueTypesError && <FormHelperText>Please select at least one test type</FormHelperText>}
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
        <RoomPreferencesIcon sx={{ mr: '8px' }} />
        <Typography sx={{ width: '180px', m: 0, fontSize: '1.25rem' }}>Login Required:</Typography>
        <FormControlLabel
          className="projectSettingsSwitchContainer"
          control={
            <Switch
              checked={loginRequired}
              onChange={handleLoginRequiredChange}
            />
          }
          label=""
          sx={{ m: 0 }}
        />
        {loginRequired && (
          <Tooltip
            title={
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: 'normal',
                  textAlign: 'left',
                  color: '#fff',
                  fontSize: '0.6875rem',
                }}
              >
                Before each page scan, Treegress will try to log in. If the login
                process fails, the scan will not start. To fix the login process,
                click on the rescan login button to update the login page information
                and check your credentials.
                <br />
                <br />
                You can disable the login option or create a new project without login
                if you want to scan all pages in &quot;guest&quot; mode.
              </Typography>
            }
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'primary.main',
                  maxWidth: '500px',
                },
              },
            }}
          >
            <IconButton size="small" sx={{ p: 0 }}>
              <InfoOutlinedIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </>
  )
}

export default GeneralTab