import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Button, Box } from '@mui/material'

const MakeLoginRequiredModal = ({ open, onClose, onSave }) => {
  const [loginPageUrl, setLoginPageUrl] = useState('')
  const [loginFormId, setLoginFormId] = useState('')
  const [error, setError] = useState('')

  const handleLoginPageURLChange = (e) => {
    setLoginPageUrl(e.target.value.trim())
    if (error) {
      setError('')
    }
  }

  const handleSave = () => {
    if (!loginPageUrl.trim()) {
      setError('Login Page URL is required.')
      return
    }
    setError('')
    onSave(loginPageUrl, loginFormId)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ width: '600px', p: "32px" }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Login Page Configuration</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box>
            <Typography variant='body1' mb={1}>
              Please provide the login page URL.
            </Typography>
            <TextField
              autoFocus
              size='small'
              margin="dense"
              label="Login page URL"
              type="url"
              fullWidth
              variant="outlined"
              value={loginPageUrl}
              onChange={handleLoginPageURLChange}
              required
              error={!!error}
              helperText={error + ' '}
            />
            <Typography variant='body1' mb={1}>
              If you have a static ID for the Login Form HTML element, you can provide it to speed up the analysis process (optional).
            </Typography>
            <TextField
              size="small"
              placeholder="e.g., 'login-form-id'"
              type="text"
              fullWidth
              variant="outlined"
              value={loginFormId}
              onChange={(e) => setLoginFormId(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default MakeLoginRequiredModal