import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Helmet } from 'react-helmet-async'
import { useProject } from '../context/ProjectContext'
// import PageService from '../services/PageService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import DetailsTab from '../components/testCase/tabs/details/DetailsTab'
import RunsTab from '../components/testCase/tabs/runs/RunsTab'

const mockTestCase = {
  id: '1',
  created_at: '2024-11-28T08:12:51.089Z',
  updated_at: '2024-11-29T08:18:51.089Z',
  name: 'Test case #1',
  pageUrl: 'https://google.com',
  pageLabel: 'Google - Home',
  status: 'Passed',
  videoUrl: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
  steps: [
    {
      id: 'step_1',
      label: 'Item 1',
      result: 'success',
      breakpoint: 0,
      children: [
        {
          id: 'step_2',
          label: 'Item 2',
          result: 'failed',
          breakpoint: 3,
          children: [
            {
              id: 'step_3',
              label: 'Item 3',
              result: 'success',
              breakpoint: 5,
              children: [
                {
                  id: 'step_4',
                  label: 'Item 4',
                  result: 'success',
                  breakpoint: 7,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'step_5',
      label: 'Item 5',
      result: 'success',
      breakpoint: 11,
      children: [
        {
          id: 'step_6',
          label: 'Item 6',
          result: 'success',
          breakpoint: 13,
          children: [],
        },
      ],
    },
    {
      id: 'step_7',
      label: 'Item 7',
      result: 'success',
      breakpoint: 14,
      children: [],
    },
  ]
}

const TestCase = () => {
  const { id } = useParams()
  const { selectedProject } = useProject()
  const [activeTabValue, setActiveTabValue] = useState('1')
  const [testCase, setTestCase] = useState(null)

  useEffect(() => {
    const getTestCase = async () => {
      try {
        // const testCases = await PageService.getTestCaseById(id)
        setTestCase(mockTestCase)
      } catch (error) {
        console.error('Error fetching test cases:', error)
      }
    }

    getTestCase()
  }, [id, selectedProject?.id])

  const handleChangeActiveTab = (event, newValue) => {
    setActiveTabValue(newValue)
  }

  return (
    <Box className="testCase">
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} ${testCase?.pageLabel} ${testCase?.name}`}</title>
      </Helmet>
      <Header title={testCase?.pageLabel}/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className="testCaseContentContainer">
          <TabContext value={activeTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'rgba(45,67,86,0.5)' }}>
              <TabList onChange={handleChangeActiveTab}>
                <Tab label="Test Case Details" value="1" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
                <Tab label="Test Runs" value="2" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: "16px 0" }}>
              <DetailsTab testCase={testCase}/>
            </TabPanel>
            <TabPanel value="2" sx={{ p: "16px 0" }}>
              <RunsTab testCase={testCase}/>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  )
}

export default TestCase