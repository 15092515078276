import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import TopPagesTableHead from "./TopPagesTableHead"
import PreviewImage from "./PreviewImage"

const TopPagesReport = ({ pages }) => {
  const scannedPages = pages
    .filter(page => page.last_scanned_at)
    .sort((a, b) => b.failed_ui_test_count - a.failed_ui_test_count)

  return (
    <Box className="dashboardDataTopPages">
      <Typography variant="h6" textAlign="center" gutterBottom>
        Top Pages Overview
      </Typography>
      <TableContainer className='topPagesTableContainer' component={Paper}>
        <Table className='topPagesTable'>
          <TopPagesTableHead />
          <TableBody>
            {scannedPages.length ?
              scannedPages.map((page, index) => (
                <TableRow
                  key={index}
                  className='topPagesTableItem'
                  sx={{
                    '&:hover': {
                      backgroundColor: '#fff !important',
                      cursor: 'default !important'
                    }
                  }}
                >
                  <TableCell>{page.label ? page.label : page.url}</TableCell>
                  <TableCell sx={{ minWidth: '180px', maxWidth: '300px', wordBreak: 'break-word' }}>{page.url}</TableCell>
                  <TableCell sx={{ minWidth: '286px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Overlapping Content"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;Content does not overlap</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Broken image"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;Images are intact</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Missing Content"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;All content is visible</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Unnecessary Scroll"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;No unnecessary scrolling</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Unreadable text"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;Text is readable</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Letter spacing issue"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;No spacing issues</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Outdated Style"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;No outdated styles</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Inconsistent Color Scheme"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;Color scheme is consistent</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Empty Layout or Missing Content"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;Not empty sections</span>
                        </>
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Broken Layout"] ?
                        null :
                        <>
                          <DoneIcon color="success" />
                          <span>&nbsp;Not broken layout</span>
                        </>
                      }
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Overlapping Content"] ?
                        <>
                          <CloseIcon color="error" />
                          {page.issue_type_counts?.["Overlapping Content"]} overlapping content{page.issue_type_counts?.["Overlapping Content"] > 1 ? "s" : null}
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Broken image"] ?
                        <>
                          <CloseIcon color="error" />
                          {page.issue_type_counts?.["Broken image"]} broken image issue{page.issue_type_counts?.["Broken image"] > 1 ? "s" : null}
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Missing Content"] ?
                        <>
                          <CloseIcon color="error" />
                          {page.issue_type_counts?.["Missing Content"]} missing content issue{page.issue_type_counts?.["Missing Content"] > 1 ? "s" : null}
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Unnecessary Scroll"] ?
                        <>
                          <CloseIcon color="error" />
                          {page.issue_type_counts?.["Unnecessary Scroll"]} unnecessary scroll issue{page.issue_type_counts?.["Unnecessary Scroll"] > 1 ? "s" : null}
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Unreadable text"] ?
                        <>
                          <CloseIcon color="error" />
                          {page.issue_type_counts?.["Unreadable text"]} text readability issue{page.issue_type_counts?.["Unreadable text"] > 1 ? "s" : null}
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Letter spacing issue"] ?
                        <>
                          <CloseIcon color="error" />
                          {page.issue_type_counts?.["Letter spacing issue"]} letter spacing issue{page.issue_type_counts?.["Letter spacing issue"] > 1 ? "s" : null}
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Outdated Style"] ?
                        <>
                          <CloseIcon color="error" />
                          Outdated style
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Inconsistent Color Scheme"] ?
                        <>
                          <CloseIcon color="error" />
                          Inconsistent color scheme
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Empty Layout or Missing Content"] ?
                        <>
                          <CloseIcon color="error" />
                          Empty layout/missing content
                        </> :
                        null
                      }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      { page.issue_type_counts?.["Broken Layout"] ?
                        <>
                          <CloseIcon color="error" />
                          Broken layout
                        </> :
                        null
                      }
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    { page?.block ? <PreviewImage page={page}/> : null }
                  </TableCell>
                </TableRow>
              )) :
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="body1" align="center">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TopPagesReport
