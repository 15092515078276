import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Container, TextField, Button, Link, Typography, Box } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ApiService from '../services/ApiService'
import { validateName, validateEmail, validatePassword, validateInvitationCode } from '../utils/validateForm'
import CustomGoogleLoginButton from '../components/CustomGoogleLoginButton'

const SignUp = () => {
  const navigate = useNavigate()

  const [settings, setSettings] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [error, setError] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await ApiService.get('/settings')
        setSettings(res)
      } catch (error) {
        console.error('Error fetching settings:', error)
      }
    }

    fetchSettings()
  }, [])

  const handleSignUp = async (e) => {
    e.preventDefault()

    let valid = true

    if (!validateName(name)) {
      setNameError('Required field.')
      valid = false
    } else {
      setNameError('')
    }

    if (!validateEmail(email)) {
      setEmailError('Invalid email.')
      valid = false
    } else {
      setEmailError('')
    }

    if (!validatePassword(password)) {
      setPasswordError('Invalid password. Minimum length 6.')
      valid = false
    } else {
      setPasswordError('')
    }

    if (!validateInvitationCode(code)) {
      setCodeError('Required field.')
      valid = false
    } else {
      setCodeError('')
    }

    if (!valid) return

    try {
      await ApiService.post('/auth/signup', { email, password, full_name: name, invitationCode: code })
      navigate('/confirmation')
    } catch (error) {
      console.log(error)
      setError(error.response.data.error)
    }
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
    if (emailError) {
      setNameError('')
    }
    if (error) {
      setError('')
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if (emailError) {
      setEmailError('')
    }
    if (error) {
      setError('')
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    if (passwordError) {
      setPasswordError('')
    }
    if (error) {
      setError('')
    }
  }

  const handleCodeChange = (e) => {
    setCode(e.target.value)
    setCodeError('')
  }

  const handleOpenExternalLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Helmet>
        <title>Treegress - Sign Up</title>
      </Helmet>
      <Box
        sx={{
          border: '1px solid #C4C4C4',
          borderRadius: '8px',
          padding: '32px'
        }}
      >
        <Typography variant="h6" align="center" fontWeight="400" gutterBottom>
          Welcome to Treegress!
        </Typography>
        <form onSubmit={handleSignUp}>
          <TextField
            label="Full Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            required
            error={!!nameError}
            helperText={nameError+' '}
            inputProps={{
              form: {
                autocomplete: 'off',
              }
            }}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            required
            error={!!emailError}
            helperText={emailError+' '}
            inputProps={{
              form: {
                autocomplete: 'off',
              }
            }}
            sx={{ mt: 0 }}
          />
          <TextField
            label="Password"
            fullWidth
            margin="normal"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            error={!!passwordError}
            helperText={passwordError+' '}
            sx={{ mt: 0 }}
          />
          <TextField
            label="Invitation code"
            fullWidth
            margin="normal"
            variant="outlined"
            value={code}
            onChange={handleCodeChange}
            required
            error={!!codeError}
            helperText={codeError+' '}
            sx={{ mt: 0 }}
            inputProps={{
              form: {
                autocomplete: 'off',
              }
            }}
          />
          {error && <Typography color="error" sx={{ margin: '-26px 0 8px 14px', fontSize: '0.75rem' }}>{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: '250px', textTransform: 'none' }}
            >
              Sign up
            </Button>
          </Box>
        </form>
        <Box my={2} display="flex" justifyContent="center">
          { settings ?
            <GoogleOAuthProvider clientId={settings.googleClientId}>
              <CustomGoogleLoginButton />
            </GoogleOAuthProvider>
            : null
          }
        </Box>
        <Typography variant="body2">
          Signing up for a Treegress account means you agree to the <span className="signUpExternalLink" onClick={() => handleOpenExternalLink('https://www.treegress.com/utility-pages/privacy-policy')}>Privacy Policy</span> and <span className="signUpExternalLink" onClick={() => handleOpenExternalLink('https://www.treegress.com/utility-pages/terms-and-conditions')}>Terms of Service</span>
        </Typography>
        <Box mt={1}>
          <Link href="/login" variant="body2">
            Have an account? Sign in
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default SignUp