import { TableHead, TableRow, TableCell, Checkbox, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SortingIcon from '../../../../components/SortingIcon'

const TableHeader = ({
  sortConfig,
  handleSortChange,
  isHeaderCheckboxChecked,
  includedIntoScanningTotal,
  paginatedPages,
  setCheckedPages,
  setIsHeaderCheckboxChecked,
  anchorEl,
  open,
  handleClickCheckAllDropdown,
  handleCloseCheckAllDropdown,
  handleCheckAllPages,
  handleUncheckAllPages
}) => {

  const handleHeaderCheckboxChange = (e) => {
    const allChecked = e.target.checked
    const currentPageIds = paginatedPages.map(page => page.id)

    setCheckedPages(prevCheckedPages => {
      if (allChecked) {
        return [...new Set([...prevCheckedPages, ...currentPageIds])]
      } else {
        return prevCheckedPages.filter(id => !currentPageIds.includes(id))
      }
    })

    setIsHeaderCheckboxChecked(allChecked)
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            position: 'sticky',
            top: 0,
            zIndex: 2,
            borderBottom: '2px solid #D7E2EC',
          },
        }}
      >
        <TableCell padding="checkbox">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              sx={{ ml: '9px', p: 0 }}
              checked={isHeaderCheckboxChecked}
              onChange={handleHeaderCheckboxChange}
            />
            <IconButton
              onClick={handleClickCheckAllDropdown}
              size="small"
              sx={{ p: 0 }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseCheckAllDropdown}
            >
              <MenuItem onClick={handleCheckAllPages} sx={{ minWidth: '100px' }}>All</MenuItem>
              <MenuItem onClick={handleUncheckAllPages}>None</MenuItem>
            </Menu>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '300px', minWidth: '160px', p: '12px 0', cursor: 'pointer' }} onClick={() => handleSortChange('label')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'label'}/>
            Page Name
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '300px', minWidth: '160px', p: '12px 8px', cursor: 'pointer' }} onClick={() => handleSortChange('url')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'url'}/>
            Page URL
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '192px', minWidth: '192px', p: '12px 8px', cursor: 'pointer' }} onClick={() => handleSortChange('created_at')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'created_at'}/>
            Last Discovery Date
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.5 }}>
              <Tooltip
                title="The date when the page was first detected and added to the website map."
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'primary.main'
                    },
                  },
                }}
              >
                <IconButton size="small" sx={{ p: 0 }}>
                  <InfoOutlinedIcon fontSize="small" color='primary'/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '152px', minWidth: '152px', p: '12px 8px', cursor: 'pointer' }} onClick={() => handleSortChange('last_scanned_at')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'last_scanned_at'}/>
            Last Scan Date
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title="The date when the page was analyzed by AI for UI issues."
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'primary.main'
                    },
                  },
                }}
              >
                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                  <InfoOutlinedIcon fontSize="small" color='primary'/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '130px', minWidth: '130px', p: '12px 8px', cursor: 'pointer' }} onClick={() => handleSortChange('passed_ui_test_count')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'passed_ui_test_count'}/>
            Passed Tests
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title="The number of issue types based on your active project settings selections, that was not found on this page."
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'primary.main'
                    },
                  },
                }}
              >
                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                  <InfoOutlinedIcon fontSize="small" color='primary'/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '100px', minWidth: '100px', p: '12px 8px', cursor: 'pointer' }} onClick={() => handleSortChange('failed_ui_test_count')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'failed_ui_test_count'}/>
            Failed Tests
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ width: '214px', minWidth: '214px', p: '12px 8px', cursor: 'pointer' }} onClick={() => handleSortChange('include_into_scanning')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'include_into_scanning'}/>
            <Box>Included into Scanning</Box>
            <Box className="includedIntoScanningBadge" sx={{ backgroundColor: '#198754' }}>{includedIntoScanningTotal}</Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title="Indicates if this page will be scanned when you click the 'Scan Included Pages' button."
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'primary.main'
                    },
                  },
                }}
              >
                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                  <InfoOutlinedIcon fontSize="small" color='primary'/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeader