import { Helmet } from 'react-helmet-async'
import { Box, List, ListItem, ListItemText, Typography, Link } from '@mui/material'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

const Tutorial = () => {
  return (
    <Box className="tutorial">
      <Helmet>
        <title>Treegress - Tutorial</title>
      </Helmet>
      <Header title="Tutorial"/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className="tutorialContent">
          <Box>
            <Typography variant="h5" sx={{ mb: 0.5 }}>
              Treegress User Guide
            </Typography>
            <List>
              <ListItem key="introduction" disablePadding sx={{ color: 'secondary.main !important' }}>
                <Link href="#introduction" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="1. Introduction"/>
                </Link>
              </ListItem>
              <ListItem key="getting-started" disablePadding>
                <Link href="#getting-started" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="2. Getting Started" />
                </Link>
              </ListItem>
              <ListItem key="website-mapping" disablePadding>
                <Link href="#website-mapping" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="3. Website Mapping" />
                </Link>
              </ListItem>
              <ListItem key="project-settings" disablePadding>
                <Link href="#project-settings" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="4. Project Settings" />
                </Link>
              </ListItem>
              <ListItem key="ui-issue-detection" disablePadding>
                <Link href="#ui-issue-detection" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="5. UI Issue Detection" />
                </Link>
              </ListItem>
              <ListItem key="ui-issue-types" disablePadding>
                <Link href="#ui-issue-types" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="6. UI Issue Types" />
                </Link>
              </ListItem>
              <ListItem key="project-summary" disablePadding>
                <Link href="#project-summary" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="7. Project Summary (Bug Report)" />
                </Link>
              </ListItem>
              <ListItem key="require-login" disablePadding>
                <Link href="#require-login" underline="hover" color="secondary">
                  <ListItemText className="tutorialContentLinkText" primary="8. Scanning Pages That Require Login" />
                </Link>
              </ListItem>
            </List>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Box key="introduction" id="introduction">
              <Typography variant="h5" sx={{ mb: 2 }}>
                1. Introduction
              </Typography>
              <Typography variant="body1">
                The first version of Treegress contains the following features:
              </Typography>
              <List>
                <ListItem key="introduction-1" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    1. Website mapping
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    The user&apos;s website is automatically discovered, and a site map is generated up to the 2nd level. Users can explore deeper levels by discovering additional child pages as needed.
                  </Typography>
                </ListItem>
                <ListItem key="introduction-2" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    2. UI Issue detection
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    Selected pages are scanned, and the AI model identifies various UI issues, including broken layouts, missing images, missing content, unreadable text, excessive scrolling, and letter spacing problems.
                  </Typography>
                </ListItem>
                <ListItem key="introduction-3" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    3. Spelling issue detection
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    The platform will check the text of every scanned page for spelling issues.
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <Box key="getting-started" id="getting-started">
              <Typography variant="h5" sx={{ mb: 2 }}>
                2. Getting Started
              </Typography>
              <Typography variant="body1">
                The first version of Treegress contains the following features:
              </Typography>
              <List>
                <ListItem key="getting-started-1" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    1. On the&nbsp;
                    <Link href="https://www.treegress.com/" underline="hover" color="secondary" sx={{ display: 'inline-block' }}>
                      <ListItemText className="tutorialContentLinkText" primary="https://www.treegress.com/" />
                    </Link>
                    &nbsp;website, click “Free Trial”
                  </Typography>
                </ListItem>
                <ListItem key="getting-started-2" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    2. Create an account: enter your full name, email, and password; or use your Google account for registration.
                  </Typography>
                </ListItem>
                <ListItem key="getting-started-3" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    3. In your Treegress account, you should see the following tabs:
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    a. Profile - contains your personal information (name, email, and password).
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    b. Projects - contains projects that you have added.
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    c. Dashboard - contains statistics about scans and issues of a selected project.
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    d. Pages - displays a list of your project&apos;s pages. It automatically includes the first and second-level pages. You can then explore deeper levels by clicking the “Discover More” button for any parent page or manually adding pages using the “Add Page” button.
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    e. Tasks - contains background tasks that you have run for a selected project.
                  </Typography>
                </ListItem>
                <ListItem key="getting-started-4" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    4. Add your first project in the  “Projects” tab via the “Add New Project” button.
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    a. In the “Add New Project” window, enter the name of your project and the project URL, then select the website resolutions you want to check.
                  </Typography>
                </ListItem>
                <ListItem key="getting-started-5" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    5. Once you save your project, the website mapping should be started and your project should be displayed in the “Projects” tab.
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <Box key="website-mapping" id="website-mapping">
              <Typography variant="h5" sx={{ mb: 2 }}>
                3. Website Mapping
              </Typography>
              <Typography variant="body1">
                Once a project has been added to Treegress, the platform discovers the structure of an added website. The process may take a few minutes.
              </Typography>
              <Typography variant="body1">
                <strong>In the “Tasks” tab</strong> you can see the status of the website mapping task. Once the website mapping is finished, the “Completed” status should be displayed (see screenshot below).
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/tasks_tab.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                <strong>In the “Pages” tab</strong> you can see the list of website pages discovered by Treegress (see screenshot below).
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/pages_tab.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                Here you can do the following actions:
              </Typography>
              <List>
                <ListItem key="website-mapping-1" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    1. Add pages from the selected website via the “Add Page” button.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-2" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    2. Select page(s) and discover more, include/exclude selected pages.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-3" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    3. Scan selected website pages.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-4" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    4. See the total number of pages on a scanned website.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-5" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    5. See the number of scanned website pages.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-6" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    6. See the number of website pages with issues.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-7" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    7. See the scanning progress.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-8" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    8. See the issue rate.
                  </Typography>
                </ListItem>
                <ListItem key="website-mapping-9" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    9. Change the view of displayed pages: table/hierarchical.
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    a. The table view displays pages by the last discovery date.
                  </Typography>
                  <Typography component="span" variant="body1" sx={{ pl: 2 }}>
                    b. The hierarchical view displays pages by hierarchy on your website.
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <Box key="project-settings" id="project-settings">
              <Typography variant="h5" sx={{ mb: 2 }}>
                4. Project Settings
              </Typography>
              <Typography variant="body1">
                You can adjust project settings after the website mapping process. To access project settings, click on the gear icon in the “Projects” tab (see screenshot).
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/projects_settings.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1" mb={1}>
                On the project settings page, you can change the project name, project URL, and environment; add/remove resolutions, and select UI tests to be applied to your project.
              </Typography>
              <Typography variant="body1">
                You can also access project settings from the list of pages on the “Pages” tab (see screenshot).
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/pages_project_settings.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                By default, only the desktop resolution is selected, and all issue types are enabled.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/project_settings.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box key="ui-issue-detection" id="ui-issue-detection">
              <Typography variant="h5" sx={{ mb: 2 }}>
                5. UI Issue Detection
              </Typography>
              <Typography variant="body1">
                UI issues detection feature works once Treegress has mapped your website. You can scan the whole website or selected website pages from the “Pages” tab. To run UI issues detection, follow these steps:
              </Typography>
              <List>
                <ListItem key="ui-issue-detection-1" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    1. On the “Pages” tab, select pages to be scanned or click the “Scan All Pages” button.
                  </Typography>
                  <Box sx={{ display:'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '60%' }}>
                      <img
                        src="https://treegress-sandbox.s3.amazonaws.com/images/scan_all_pages.png"
                        alt="tutorial-image"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography component="span" variant="body1" sx={{ margin: '8px 0 16px 16px' }}>
                    “Scan All Pages” function
                  </Typography>
                  <Box sx={{ display:'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '60%' }}>
                      <img
                        src="https://treegress-sandbox.s3.amazonaws.com/images/scan_selected_pages.png"
                        alt="tutorial-image"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography component="span" variant="body1" sx={{ margin: '8px 0 16px 16px' }}>
                    “Scan Selected Pages” function
                  </Typography>
                </ListItem>
                <ListItem key="ui-issue-detection-2" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    2. Once you click on the scan button, Treegress scans selected or all website pages. The scanning status is shown on the “Tasks” tab. Once selected website pages are scanned, the “Completed” status should be displayed.
                  </Typography>
                  <Box sx={{ display:'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '60%' }}>
                      <img
                        src="https://treegress-sandbox.s3.amazonaws.com/images/tasks_completed_status.png"
                        alt="tutorial-image"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  </Box>
                </ListItem>
                <ListItem key="ui-issue-detection-3" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2, mt: 2 }}>
                  <Typography component="span" variant="body1">
                    3. Click on the task in the table to view detected issues.
                  </Typography>
                  <Box sx={{ display:'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '60%' }}>
                      <img
                        src="https://treegress-sandbox.s3.amazonaws.com/images/detected_issues.png"
                        alt="tutorial-image"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography component="span" variant="body1" sx={{ margin: '8px 0 16px 16px' }}>
                    You can view all the detected UI issues. In the “Page analysis results” section you can see the screenshot of the website page with issues highlighted on it, and the type of each defined issue in the “Issues” part.
                  </Typography>
                </ListItem>
                <ListItem key="ui-issue-detection-4" disablePadding sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                  <Typography component="span" variant="body1">
                    4. When no issues are detected, you will see the “No UI issues were found” message (see example below).
                  </Typography>
                  <Box sx={{ display:'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '60%' }}>
                      <img
                        src="https://treegress-sandbox.s3.amazonaws.com/images/no_issues.png"
                        alt="tutorial-image"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  </Box>
                </ListItem>
              </List>
            </Box>
            <Box key="ui-issue-types" id="ui-issue-types">
              <Typography variant="h5" sx={{ my: 2 }}>
                6. UI Issue Types
              </Typography>
              <Typography variant="body1">
                1) <strong>Unreadable Text:</strong> Font color is difficult to read against the background color, compromising text visibility.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/unreadable_text.jpg"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                2) <strong>Broken Image:</strong> Image link is broken, resulting in the image not being displayed.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/broken_image.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                3) <strong>Missing Content:</strong> Expected content is missing, such as an empty iframe or section where content should appear.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/missing_content.jpg"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                4) <strong>Unnecessary Scroll:</strong> A layout issue causing scrollbars to appear where they could be avoided.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/unnecessary_scroll.jpg"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                5) <strong>Letter Spacing Issue:</strong> Excessive or inconsistent spacing between letters makes the text difficult to read.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/letter_spacing_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                6) <strong>Inconsistent Font Size:</strong> Font size is significantly different from the rest of the page, disrupting visual harmony.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/inconsistent_font_size_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                7) <strong>Outdated Style:</strong> The page appears as though CSS is not applied, or the styling resembles outdated, legacy design.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/outdated_style_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                8) <strong>Inconsistent Color Scheme:</strong> The use of too many colors disrupts the user experience and visual consistency.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/inconsistent_color_scheme_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                9) <strong>Empty Layout or Missing Content:</strong> Large empty spaces or minimal content indicate sections that are not properly loaded or displayed.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/empty_layout_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                10) <strong>Broken Layout:</strong> Missing or unrendered styles lead to visual disruptions and layout issues.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/broken_layout_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                11) <strong>Overlapping Content:</strong> Content overlaps, making it difficult or impossible to read.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/overlapping_content_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1">
                12) <strong>Unnecessary Horizontal Scroll:</strong> This occurs when the page shows a horizontal scroll bar, even though it shouldn’t at the current screen resolution.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/unnecessary_horizontal_scroll_2.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box key="project-summary" id="project-summary">
              <Typography variant="h5" sx={{ mb: 2 }}>
                7. Project Summary (Bug Report)
              </Typography>
              <Typography variant="body1">
                You can view a summary of UI issue scanning in the “Dashboard” tab. The summary contains the statistics about the number of total issues defined on the scanned pages, browser versions, and screen resolutions tested. You can also view graphs for issue distribution by type and issue rate over time.
              </Typography>
              <Box sx={{ display:'flex', justifyContent: 'center', my: 1 }}>
                <Box sx={{ width: '60%' }}>
                  <img
                    src="https://treegress-sandbox.s3.amazonaws.com/images/dashboard.png"
                    alt="tutorial-image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body1" mb={2}>
                You can share the report from the “Dashboard” tab via the “Share” button. This report will be accessible to everyone who has a link to the report. The report doesn’t compromise any secure user’s data.
              </Typography>
            </Box>
            <Box key="require-login" id="require-login">
              <Typography variant="h5" sx={{ mb: 2 }}>
                8. Scanning Pages That Require Login
              </Typography>
              <Typography variant="body1" mb={2}>
                To detect UI issues on pages that require login, you can provide login credentials. Before proceeding, please check the field mapping suggested by AI to ensure the correct fields are identified for username, password, and the login button.
              </Typography>
              <Typography variant="body1" mb={2}>
                Limitations in the Current Version:
              </Typography>
              <Typography variant="body1">
                1) Only simple login forms are supported:
              </Typography>
              <Typography variant="body2" ml={3} my={1}>
                &#x2022; Must include only a username and password field, and a login button.<br/>
                &#x2022; No support for captchas or multi-step login processes.
              </Typography>
              <Typography variant="body1" mb={2}>
                2) The login form must have its own unique URL for the scanning process to work.
              </Typography>
              <Typography variant="body1" mb={2}>
                3) Each project is optimized for either scanning pages without login, or scanning pages with login.
              </Typography>
              <Typography variant="body1" mb={2}>
                You can update the login credentials at any time in the project settings.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Tutorial