export const validateName = (name) => {
  return name.trim() !== ''
}

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const validatePassword = (password) => {
  return password.length >= 6
}

export const validateInvitationCode = (code) => {
  return code.trim() !== ''
}

export const validateProjectName = (name) => {
  return name.trim() !== ''
}

export const validateProjectURL = (website) => {
  const regex = /^(?:(?:https?):\/\/)?(?:[a-zA-Z0-9-]+(?::[^\s@]*)?@)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d{1,5})?(?:\/[^\s?#]*)*(?:\?[^\s#]*)?(?:#[^\s]*)?$/
  return regex.test(website)
}

export const validateLoginPageURL = (website) => {
  const regex = /^(?:(?:https?):\/\/)?(?:[a-zA-Z0-9-]+(?::[^\s@]*)?@)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d{1,5})?(?:\/[^\s?#]*)*(?:\?[^\s#]*)?(?:#[^\s]*)?$/
  return regex.test(website)
}