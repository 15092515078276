import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProject } from '../../context/ProjectContext'
import { useAllProjects } from '../../context/AllProjectsContext'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Tooltip,
  IconButton
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useNotification } from '../../context/NotificationContext'
import ProjectService from '../../services/ProjectService'
import { validateProjectName, validateProjectURL, validateLoginPageURL } from '../../utils/validateForm'

const CreateProjectModal = ({
  open,
  setOpen,
  projectName,
  setProjectName,
  projectURL,
  setProjectURL,
  projectsToggler,
  setProjectsToggler,
  isExistedProjects
}) => {
  const navigate = useNavigate()
  const showNotification = useNotification()
  const { setSelectedProject } = useProject()
  const { refreshProjects } = useAllProjects()

  const [createProjectLoading, setCreateProjectLoading] = useState(false)
  const [projectNameError, setProjectNameError] = useState('')
  const [projectURLError, setProjectURLError] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [selectedResolutions, setSelectedResolutions] = useState(['desktop'])
  const [loginRequired, setLoginRequired] = useState(false)
  const [loginFormId, setLoginFormId] = useState('')
  const [loginPageUrl, setLoginPageUrl] = useState('')
  const [loginPageURLError, setLoginPageURLError] = useState('')

  const steps = ['Add Project URL', 'Add Project Name', 'Select Settings']

  const handleClose = () => {
    setOpen(false)
    setActiveStep(0)
    setProjectURL('')
    setProjectName('')
    setProjectURLError('')
    setSelectedResolutions(['desktop'])
    setLoginRequired(false)
  }

  const handleBack = (step) => {
    setActiveStep(step - 1)
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!validateProjectURL(projectURL)) {
        setProjectURLError('Invalid URL.')
        return
      }

      const domain = projectURL.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '').split('/')[0]
      const formattedName = domain.charAt(0).toUpperCase() + domain.slice(1)
      setProjectName(formattedName)
      setActiveStep(1)
    } else if (activeStep === 1) {
      if (!validateProjectName(projectName)) {
        setProjectNameError('Project name is required.')
        return
      }
      setActiveStep(2)
    } else {
      if (loginRequired && !validateLoginPageURL(loginPageUrl)) {
        setLoginPageURLError('Invalid login page URL.')
        return
      }

      try {
        setCreateProjectLoading(true)
        const newProject = await ProjectService.createProject(projectName, projectURL, selectedResolutions, loginRequired, loginPageUrl, loginFormId)
        await refreshProjects()
        setSelectedProject(newProject)
        if (loginRequired) {
          showNotification('Login Elements Processing has started', 'info', 'Learn more', navigate)
        } else {
          showNotification('Website Mapping has started', 'info', 'Learn more', navigate)
        }
        if (loginRequired) {
          navigate(`/project/${newProject.id}`)
        }
        setCreateProjectLoading(false)
        handleClose()
        setProjectsToggler(!projectsToggler)
      } catch (error) {
        setProjectURLError('Failed to create project.')
        setActiveStep(0)
        setCreateProjectLoading(false)
        console.log(error)
      }
    }
  }

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value)
    if (projectNameError) {
      setProjectNameError('')
    }
  }

  const handleProjectURLChange = (e) => {
    setProjectURL(e.target.value.trim())
    if (projectURLError) {
      setProjectURLError('')
    }
  }

  const handleResolutionChange = (resolution) => {
    setSelectedResolutions((prev) =>
      prev.includes(resolution)
        ? prev.filter((res) => res !== resolution)
        : [...prev, resolution]
    )
  }

  const handleLoginPageURLChange = (e) => {
    setLoginPageUrl(e.target.value.trim())
    if (loginPageURLError) {
      setLoginPageURLError('')
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ width: '600px', minHeight: '330px', display: 'flex', flexDirection: 'column', p: '32px' }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Create New Project</DialogTitle>
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContent sx={{ p: 0 }}>
          { !isExistedProjects ?
            <Typography variant='body1' mb={1}>
                You don&apos;t have any projects yet. Add your first project to start exploring and improving the quality of your solution.
            </Typography> :
            null
          }
          {activeStep === 0 && (
            <>
              <Typography variant='body1' mb={1}>
                Please enter the URL of your project.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Project URL"
                type="url"
                fullWidth
                variant="outlined"
                value={projectURL}
                onChange={handleProjectURLChange}
                required
                error={!!projectURLError}
                helperText={projectURLError + ' '}
              />
            </>
          )}

          {activeStep === 1 && (
            <>
              <Typography variant='body1' mb={1}>
                Please confirm or edit the project name.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Project Name"
                type="text"
                fullWidth
                variant="outlined"
                value={projectName}
                onChange={handleProjectNameChange}
                required
                error={!!projectNameError}
                helperText={projectNameError + ' '}
              />
            </>
          )}

          {activeStep === 2 && (
            <>
              <Typography variant='body1'>
                You can now configure additional settings for your project.
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography mr={2}>
                  Resolutions:
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={selectedResolutions.includes('desktop')}
                      onChange={() => handleResolutionChange('desktop')}
                    />
                  }
                  label={<span style={{ fontSize: '16px' }}>Desktop</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={selectedResolutions.includes('tablet')}
                      onChange={() => handleResolutionChange('tablet')}
                    />
                  }
                  label={<span style={{ fontSize: '16px' }}>Tablet</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={selectedResolutions.includes('mobile')}
                      onChange={() => handleResolutionChange('mobile')}
                    />
                  }
                  label={<span style={{ fontSize: '16px' }}>Mobile</span>}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography variant='body1' sx={{ mt: '2px', mr: '6px' }}>
                  Do the pages you want to scan require a login?
                </Typography>
                <Tooltip
                  title={
                    <Typography variant="body2" sx={{ whiteSpace: 'normal', textAlign: 'left', color: '#fff', fontSize: '0.6875rem' }}>
                      Before each page scan, Treegress will try to log in. If the login process fails, the scan will not start.
                      To fix the login process, click on the rescan login button to update the login page information and check your credentials.
                      <br />
                      <br />
                      You can disable the log in option or create a new project without login if you want to scan all pages in &quot;guest&quot; mode.
                    </Typography>
                  }
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'primary.main',
                        maxWidth: '500px'
                      },
                    },
                  }}
                >
                  <IconButton size="small" sx={{ p: 0 }}>
                    <InfoOutlinedIcon fontSize="small" color='primary'/>
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={loginRequired}
                    onChange={(e) => setLoginRequired(e.target.value === "true")}
                  >
                    <FormControlLabel value="true"control={<Radio />} label={<span style={{ fontSize: '16px' }}>Yes</span>}/>
                    <FormControlLabel value="false" control={<Radio />} label={<span style={{ fontSize: '16px' }}>No</span>} />
                  </RadioGroup>
                </FormControl>
              </Box>
              { loginRequired ?
                <Box>
                  <Typography variant='body1' mb={1}>
                    Please provide the login page URL.
                  </Typography>
                  <TextField
                    autoFocus
                    size='small'
                    margin="dense"
                    label="Login page URL"
                    type="url"
                    fullWidth
                    variant="outlined"
                    value={loginPageUrl}
                    onChange={handleLoginPageURLChange}
                    required
                    error={!!loginPageURLError}
                    helperText={loginPageURLError + ' '}
                  />
                  <Typography variant='body1' mb={1}>
                    If you have a static ID for the Login Form HTML element, you can provide it to speed up the analysis process (optional).
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="e.g., 'login-form-id'"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={loginFormId}
                    onChange={(e) => setLoginFormId(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                </Box> : null }
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ height: '100%', justifyContent: 'center', alignItems: 'flex-end', p: 0, mt: 1 }}>
          <Button onClick={activeStep === 0 ? handleClose : () => handleBack(activeStep)} variant="outlined" sx={{ width: '130px', mr: 2 }}>
            {activeStep === 0 ? 'Cancel' : 'Back'}
          </Button>
          {createProjectLoading ? (
            <LoadingButton
              loading
              variant="contained"
              color="secondary"
              loadingIndicator={<CircularProgress color="white" size={16} />}
              sx={{ width: '130px', textTransform: 'none' }}
            >
              {activeStep === steps.length - 1 ? 'Save' : 'Next'}
            </LoadingButton>
          ) : (
            <Button onClick={handleNext} variant="contained" color="secondary" sx={{ width: '130px', textTransform: 'none' }}>
              {activeStep === steps.length - 1 ? 'Save' : 'Next'}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CreateProjectModal