import { Box, Typography, Tooltip } from '@mui/material'

const TaskLoad = ({ loadStatus }) => {
  let currentTitle

  // eslint-disable-next-line
  switch (true) {
  case (loadStatus === "Low"):
    currentTitle = '1-5 minutes waiting time. You are very close to your test results.'
    break

  case (loadStatus === "Normal"):
    currentTitle = '5-10 minutes waiting time. The results will be soon, but you have time to grab a coffee.'
    break

  case (loadStatus === "High"):
    currentTitle = '10-25 minutes waiting time. Time to check your tickets or refactor that old code.'
    break

  case (loadStatus === "Very High"):
    currentTitle = '1h+ waiting time. The queue is as busy as a team stand-up after a major release. Time to work on your "to-do" list while we process the queue.'
    break
  }

  return (
    <Tooltip
      title={currentTitle}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'rgb(229, 246, 253)',
            color: 'primary.main'
          }
        },
      }}
    >
      <Box
        sx={{
          minWidth: loadStatus === "Low" ? '132px' :
            loadStatus === "Normal" ? '156px' :
              loadStatus === "High" ? '136px' : '172px',
          bgcolor: loadStatus === "Low" ? '#198754' :
            loadStatus === "Normal" ? '#4574D2' :
              loadStatus === "High" ? '#F8DF73' : '#D32F2F',
          borderRadius: 1,
          mr: 1.5,
          px: 1
        }}>
        <Typography
          variant="body2"
          sx={{
            color: loadStatus === "High" ? 'intial' : '#fff',
            whiteSpace: 'nowrap'
          }}
        >
        Task Load - <strong style={{ color: 'inherit' }}>{loadStatus}</strong>
        </Typography>
      </Box>
    </Tooltip>

  )
}

export default TaskLoad