import { useState } from 'react'
import { Container, Box, Typography, Button, TextField } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import ApiService from '../services/ApiService'
import { useAuth } from '../context/AuthContext'

const Invitation = () => {
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const googleAccessToken = location.state?.googleAccessToken

  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)

  const handleSignIn = async () => {
    try {
      if (googleAccessToken) {
        if (code) {
          const data = await ApiService.post('/auth/google-login', { google_access_token: googleAccessToken, invitationCode: code })
          const { access_token, refresh_token, user } = data
          login(access_token, refresh_token, user.id)
          navigate('/')
        } else {
          setCodeError(true)
        }
      }
    } catch (error) {
      console.log(error)
      if (googleAccessToken) {
        setCodeError(true)
      } else {
        navigate('/login')
      }
    }
  }

  const handleCodeChange = (e) => {
    setCode(e.target.value)
    setCodeError(false)
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Helmet>
        <title>Treegress - Invitation</title>
      </Helmet>
      <Box
        sx={{
          width: '526px',
          border: '1px solid #C4C4C4',
          borderRadius: '8px',
          padding: '24px'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Please provide your invitation code for Sign in
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            size='small'
            label="Invitation code"
            value={code}
            onChange={handleCodeChange}
            required
            error={codeError}
            helperText={codeError ? "Invitation code error" : null}
            autoComplete="code"
          />
          <Box>
            <Button variant="contained" color="secondary" onClick={handleSignIn}>
            Sign in
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Invitation