import { useRef } from 'react'
import { Box, Typography, IconButton, Tooltip } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
// import PageService from '../../../../services/PageService'
import TestCaseActions from './TestCaseActions'
import Steps from './Steps'
import VideoPlayer from '../../../VideoPlayer'

const DetailsTab = ({ testCase }) => {
  const videoRef = useRef(null)

  const handleRunTestCase = async () => {
    try {
      console.log('handleRunTestCase!')
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenLink = () => {
    window.open(testCase.pageUrl, '_blank', 'noopener,noreferrer')
  }

  const handleSeek = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
      // videoRef.current.play()
    }
  }

  return (
    <Box className="testCaseDetailsTab">
      <Box className="testCaseDetailsTabTitle">
        <Typography variant="h6" color="primary">{testCase?.name}</Typography>
        <TestCaseActions handleRunTestCase={handleRunTestCase}/>
      </Box>
      <Box className="testCaseDetailsTabPageUrl">
        <Typography variant="body1" color="primary">{testCase?.pageUrl}</Typography>
        <Tooltip
          title="Open in a new tab"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ ml: 0.5 }} onClick={handleOpenLink}>
            <OpenInNewIcon color='primary'/>
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="testCaseDetailsTabContent">
        <Box className="testCaseDetailsTabContentSteps">
          { testCase?.steps ? <Steps steps={testCase.steps} handleSeek={handleSeek}/> : null }
        </Box>
        <Box className="testCaseDetailsTabContentVideo">
          <VideoPlayer ref={videoRef} videoSrc={testCase?.videoUrl}/>
        </Box>
      </Box>
    </Box>
  )
}

export default DetailsTab