import ApiService from "./ApiService"

const ProjectService = {
  getAllProjects: async function (page, limit=10) {
    const endpoint = `/projects?page=${page}&limit=${limit}`
    return await ApiService.get(endpoint)
  },
  getProjectById: async function (projectId) {
    const endpoint = `/projects/${projectId}`
    return await ApiService.get(endpoint)
  },
  createProject: async function (projectName, projectURL, screenSizes, loginRequired, loginPageUrl, loginFormId) {
    const endpoint = '/projects'
    return await ApiService.post(endpoint, { name: projectName, url: projectURL, screenSizes, loginRequired, loginPageUrl, loginFormId })
  },
  getProjectLoginDataById: async function (projectId) {
    const endpoint = `/projects/auth-data/${projectId}`
    return await ApiService.get(endpoint)
  },
  testProjectLoginById: async function (projectId) {
    const endpoint = `/projects/test-login/${projectId}`
    return await ApiService.post(endpoint)
  },
  getProjectSitemap: async function (projectURL) {
    const endpoint = '/sitemap'
    return await ApiService.post(endpoint, { startUrl: projectURL })
  },
  getProjectSitemapByProjectId: async function (projectId) {
    const endpoint = `/sitemap/${projectId}`
    return await ApiService.get(endpoint)
  },
  getIssueTypes: async function () {
    const endpoint = '/issue-classes'
    return await ApiService.get(endpoint)
  },
  updateProject: async function (projectID, projectName, projectEnvironment, issueClassIds, updatedScreenSizes, loginRequired) {
    const endpoint = `/projects/${projectID}`
    return await ApiService.put(endpoint, { name: projectName, environment: projectEnvironment, issueClassIds, screenSizes: updatedScreenSizes, loginRequired })
  },
  updateProjectLoginData: async function (projectId, data) {
    const endpoint = `/projects/auth-data/${projectId}`
    return await ApiService.put(endpoint, data)
  },
  rescanProjectLoginData: async function (projectId, data) {
    const endpoint = `/projects/auth-data/${projectId}`
    return await ApiService.post(endpoint, data)
  },
  deleteProject: async function (projectId) {
    const endpoint = `/projects/${projectId}`
    return await ApiService.delete(endpoint, { id: projectId })
  },
  deleteProjects: async function (projectsId) {
    const endpoint = '/projects/delete'
    return await ApiService.post(endpoint, { ids: projectsId })
  },
  discoverMorePage: async function (projectId, pageIds) {
    const endpoint = '/sitemap'
    return await ApiService.post(endpoint, { projectId, pageIds })
  },
  getProjectSummary: async function (projectId) {
    const endpoint = `/projects/summary/${projectId}`;
    return await ApiService.get(endpoint);
  },
  getProjectReportSummary: async function (reportId) {
    const endpoint = `/report/${reportId}`;
    return await ApiService.get(endpoint);
  },
}

export default ProjectService