import { Box } from '@mui/material'

const ElementLayer = ({ top, right, bottom, left }) => {
  return (
    <Box
      className="boundingBox"
      sx={{
        position: 'absolute',
        top: `${top * 100}%`,
        left: `${left * 100}%`,
        width: `${(right - left) * 100}%`,
        height: `${(bottom - top) * 100}%`,
        border: '4px solid #D32F2F',
        zIndex: 2,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    />
  )
}

export default ElementLayer