import { useImperativeHandle, forwardRef, useRef } from 'react'
import { Box } from '@mui/material'

const VideoPlayer = forwardRef(({ videoSrc }, ref) => {
  const videoRef = useRef(null)

  useImperativeHandle(ref, () => videoRef.current)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '1024px',
        margin: 'auto',
      }}
    >
      <video
        ref={videoRef}
        controls
        src={videoSrc}
        style={{
          width: '100%',
          maxHeight: '576px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        Your browser does not support the video tag.
      </video>
    </Box>
  )
})

VideoPlayer.displayName = 'VideoPlayer'

export default VideoPlayer
