import { Box, Typography } from '@mui/material'

const TestRecommendationsTab = ({ page }) => {
  console.log(page.page.id)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 170px)' }}>
      <Typography variant="body1">Stay tuned! Functional test case recommendations will show up here with our next platform update.</Typography>
    </Box>
  )
}

export default TestRecommendationsTab

// import { useState, useEffect } from 'react'
// import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Checkbox, Menu, MenuItem, IconButton, Tooltip } from '@mui/material'
// import OpenInNewIcon from '@mui/icons-material/OpenInNew'
// import MoreVertIcon from '@mui/icons-material/MoreVert'
// // import PageService from '../../../../services/PageService'
// import StatsSummary from '../../../StatsSummary'
// import TestCasesActions from './TestCasesActions'
// import SortingIcon from '../../../SortingIcon'
// import TestCasesListItem from './TestCasesListItem'
// import DeleteTestCasesConfirmationModal from './DeleteTestCasesConfirmationModal'
// import { sortTableItems } from '../../../../utils/sortTableItems'

// const mockTestCases = [
//   {
//     id: '1',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #1',
//     status: 'Passed',
//   },
//   {
//     id: '2',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #2',
//     status: 'Not Started',
//   },
//   {
//     id: '3',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: null,
//     name: 'Test case #3',
//     status: 'Failed',
//   },
//   {
//     id: '4',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #4',
//     status: 'In Progress',
//   },
//   {
//     id: '5',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #5',
//     status: 'Not Started',
//   },
//   {
//     id: '6',
//     created_at: '2024-11-27T08:10:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #6',
//     status: 'Not Started',
//   },
//   {
//     id: '7',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: null,
//     name: 'Test case #7',
//     status: 'Passed',
//   },
//   {
//     id: '8',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #8',
//     status: 'Passed',
//   },
//   {
//     id: '9',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #9',
//     status: 'Failed',
//   },
//   {
//     id: '10',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #10',
//     status: 'Failed',
//   },
//   {
//     id: '11',
//     created_at: '2024-11-28T08:12:51.089Z',
//     updated_at: '2024-11-29T08:18:51.089Z',
//     name: 'Test case #11',
//     status: 'Passed',
//   },
// ]

// const TestCasesTab = ({ pageData }) => {
//   const [allTestCases, setAllTestCases] = useState([])
//   const [testCasesTotal, setTestCasesTotal] = useState(0)
//   const [checkedTestCases, setCheckedTestCases] = useState([])
//   const [testCasesToDelete, setTestCasesToDelete] = useState([])
//   const [anchorEl, setAnchorEl] = useState(null)
//   const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false)
//   const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' })
//   const [isGeneratingTestCasesLoading, setIsGeneratingTestCasesLoading] = useState(false)
//   const [page, setPage] = useState(1)
//   const [deleteOpen, setDeleteOpen] = useState(false)

//   const openAnchorEl = Boolean(anchorEl)
//   const testCasesPerPage = 10
//   const startItem = (page - 1) * testCasesPerPage + 1
//   const endItem = Math.min(page * testCasesPerPage, testCasesTotal)

//   useEffect(() => {
//     const getAllTestCases = async () => {
//       try {
//         // const testCases = await PageService.getTestCases(1, 1000)
//         // setAllTestCases(testCases)
//         // setTestCasesTotal(testCases?.length)
//         setAllTestCases(mockTestCases)
//         setTestCasesTotal(mockTestCases.length)
//       } catch (error) {
//         console.error('Error fetching test cases:', error)
//       }
//     }

//     getAllTestCases()
//   }, [])

//   const handleRunTestCases = async () => {
//     try {
//       await new Promise(resolve => setTimeout(resolve, 2000))
//       console.log('handleRunTestCases!')
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   const handleGenerateTestCases = async () => {
//     setIsGeneratingTestCasesLoading(true)
//     try {
//       await new Promise(resolve => setTimeout(resolve, 2000))
//       console.log('handleGenerateTestCases!')
//     } catch (error) {
//       console.log(error)
//     } finally {
//       setIsGeneratingTestCasesLoading(false)
//     }
//   }

//   const handleOpenLink = () => {
//     window.open(page?.page?.url, '_blank', 'noopener,noreferrer')
//   }

//   const handleOpenDeleteDialog = (testCaseID) => {
//     if (testCaseID) {
//       setTestCasesToDelete([testCaseID])
//     } else {
//       setTestCasesToDelete(checkedTestCases)
//     }
//     setDeleteOpen(true)
//   }

//   const handleClickCheckAllDropdown = (event) => {
//     setAnchorEl(event.currentTarget)
//   }

//   const handleCloseCheckAllDropdown = () => {
//     setAnchorEl(null)
//   }

//   const handleCheckAllCurrentPage = () => {
//     const currentPageTestCaseIds = paginatedTestCases.map(testCase => testCase.id)
//     setCheckedTestCases(prevCheckedTestCases => [
//       ...new Set([...prevCheckedTestCases, ...currentPageTestCaseIds])
//     ])
//     setIsHeaderCheckboxChecked(true)
//     handleCloseCheckAllDropdown()
//   }

//   const handleUncheckAllCurrentPage = () => {
//     const currentPageTestCaseIds = paginatedTestCases.map(testCase => testCase.id)
//     setCheckedTestCases(prevCheckedTestCases =>
//       prevCheckedTestCases.filter(testCaseId => !currentPageTestCaseIds.includes(testCaseId))
//     )
//     setIsHeaderCheckboxChecked(false)
//     handleCloseCheckAllDropdown()
//   }

//   const handlePageChange = (event, value) => {
//     setPage(value)
//   }

//   const handleCheckboxChange = (id) => {
//     setCheckedTestCases(prevCheckedTestCases => {
//       if (prevCheckedTestCases.includes(id)) {
//         return prevCheckedTestCases.filter(testCaseId => testCaseId !== id)
//       } else {
//         return [...prevCheckedTestCases, id]
//       }
//     })
//   }

//   const handleSortChange = (key) => {
//     setSortConfig((prevConfig) => ({
//       key,
//       direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
//     }))
//   }

//   const sortedTestCases = sortTableItems(allTestCases, sortConfig)

//   const paginatedTestCases = sortedTestCases.slice((page - 1) * testCasesPerPage, page * testCasesPerPage)

//   return (
//     <Box>
//       <Box sx={{ display: 'flex' }}>
//         <StatsSummary
//           title="Total test cases"
//           value={ mockTestCases.length ?
//             mockTestCases.length :
//             'N/A'
//           }
//           color="primary"
//         />
//       </Box>
//       <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <Typography variant="h6" color="primary" sx={{ m: '8px 0' }}>{pageData?.page?.label}</Typography>
//         <TestCasesActions
//           checkedTestCases={checkedTestCases}
//           handleRunTestCases={handleRunTestCases}
//           isGeneratingTestCasesLoading={isGeneratingTestCasesLoading}
//           handleGenerateTestCases={handleGenerateTestCases}
//         />
//       </Box>
//       <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//         <Typography variant="body1" color="primary">{pageData?.page?.url}</Typography>
//         <Tooltip
//           title="Open in a new tab"
//           PopperProps={{
//             sx: {
//               '& .MuiTooltip-tooltip': {
//                 backgroundColor: 'primary.main'
//               },
//             },
//           }}
//         >
//           <IconButton sx={{ ml: 0.5 }} onClick={handleOpenLink}>
//             <OpenInNewIcon color='primary'/>
//           </IconButton>
//         </Tooltip>
//       </Box>
//       <TableContainer className='testCasesTableContainer' component={Paper} sx={{ boxShadow: 'none' }}>
//         <Table className='testCasesTable' stickyHeader>
//           <TableHead sx={{ borderBottom: '2px solid #D7E2EC' }}>
//             <TableRow>
//               <TableCell>
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Checkbox
//                     sx={{ ml: '17px', p: 0 }}
//                     checked={isHeaderCheckboxChecked}
//                     onChange={(e) => {
//                       const allChecked = e.target.checked
//                       if (allChecked) {
//                         handleCheckAllCurrentPage()
//                       } else {
//                         handleUncheckAllCurrentPage()
//                       }
//                     }}
//                   />
//                   <IconButton
//                     onClick={handleClickCheckAllDropdown}
//                     size="small"
//                     sx={{ p: 0 }}
//                   >
//                     <MoreVertIcon />
//                   </IconButton>
//                   <Menu
//                     anchorEl={anchorEl}
//                     open={openAnchorEl}
//                     onClose={handleCloseCheckAllDropdown}
//                   >
//                     <MenuItem onClick={handleCheckAllCurrentPage} sx={{ minWidth: '100px' }}>All</MenuItem>
//                     <MenuItem onClick={handleUncheckAllCurrentPage}>None</MenuItem>
//                   </Menu>
//                 </Box>
//               </TableCell>
//               <TableCell sx={{ minWidth: '200px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('name')}>
//                 <Box className="tableCellContentWithBorder">
//                   <SortingIcon sortConfig={sortConfig} currentKey={'name'}/>
//                   Test Case Name
//                 </Box>
//               </TableCell>
//               <TableCell sx={{ minWidth: '202px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('status')}>
//                 <Box className="tableCellContentWithBorder">
//                   <SortingIcon sortConfig={sortConfig} currentKey={'status'}/>
//                   Execution Status
//                 </Box>
//               </TableCell>
//               <TableCell sx={{ minWidth: '160px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('created_at')}>
//                 <Box className="tableCellContentWithBorder">
//                   <SortingIcon sortConfig={sortConfig} currentKey={'created_at'}/>
//                   Creation Date
//                 </Box>
//               </TableCell>
//               <TableCell sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('updated_at')}>
//                 <Box className="tableCellContentWithBorder">
//                   <SortingIcon sortConfig={sortConfig} currentKey={'updated_at'}/>
//                   Last Run Date
//                 </Box>
//               </TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             { paginatedTestCases.length ?
//               paginatedTestCases.map((testCase) => (
//                 <TestCasesListItem
//                   key={testCase.id}
//                   testCase={testCase}
//                   checkedTestCases={checkedTestCases}
//                   handleCheckboxChange={handleCheckboxChange}
//                   handleOpenDeleteDialog={handleOpenDeleteDialog}
//                 />
//               )) :
//               <TableRow>
//                 <TableCell colSpan={6}>
//                   <Typography variant="body1" align="center">
//                     No data available. Click on &apos;Generate Test Cases&apos; button.
//                   </Typography>
//                 </TableCell>
//               </TableRow>
//             }
//           </TableBody>
//         </Table>
//       </TableContainer>
//       { allTestCases.length ?
//         <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
//           <Typography variant="body2" mr={1}>
//             {`${startItem}-${endItem} of ${testCasesTotal}`}
//           </Typography>
//           <Pagination
//             count={Math.ceil(testCasesTotal / testCasesPerPage)}
//             page={page}
//             onChange={handlePageChange}
//             shape="rounded"
//             color='primary'
//           />
//         </Box> : null }
//       <DeleteTestCasesConfirmationModal
//         deleteOpen={deleteOpen}
//         setDeleteOpen={setDeleteOpen}
//         // projectsToggler={projectsToggler}
//         // setProjectsToggler={setProjectsToggler}
//         testCasesToDelete={testCasesToDelete}
//         setCheckedTestCases={setCheckedTestCases}
//       />
//     </Box>
//   )
// }

// export default TestCasesTab